import isArray from 'lodash/isArray'
import isObject from 'lodash/isObject'
import keys from 'lodash/keys'

// Formerly LZ.new
// "export function new" doesn't work in Babel
export function create(type, ...args) {
  if (!type) throw new Error('Null type passed into object create!')

  const o = Object.create(type)
  if (o.initialize) o.initialize(...args)

  return o
}

export function extend(proto, properties = {}) {
  return { ...proto, ...properties }
}

// Useful to pass in a transform function such as lodash's snakeCase or camelCase to transform keys.
export function deepKeyTransform(value, transformFunction, options = {}) {
  options.skipKeyChildren ||= []
  if (typeof transformFunction !== 'function') {
    throw new TypeError('Received ' + typeof transformFunction + ' for transformFunction. Must be function.')
  }
  if (isArray(value)) return value.map((arrValue) => deepKeyTransform(arrValue, transformFunction, options))
  if (!isObject(value)) return value

  const output = {}
  keys(value).forEach((key) => {
    output[transformFunction(key)] = options.skipKeyChildren.includes(key)
      ? value[key]
      : deepKeyTransform(value[key], transformFunction, options)
  })
  return output
}

export function keyTransform(value, transformFunction) {
  if (typeof transformFunction !== 'function') {
    throw new TypeError('Received ' + typeof transformFunction + ' for transformFunction. Must be function.')
  }
  if (!isObject(value)) return value

  const output = {}
  keys(value).forEach((key) => {
    output[transformFunction(key)] = value[key]
  })
  return output
}

export function filter(obj, cb = (v) => v) {
  if (Array.isArray(obj)) return obj.filter(cb)

  return Object.entries(obj).reduce((out, [key, val]) => {
    if (!cb(val, key)) return out
    out[key] = val
    return out
  }, {})
}
