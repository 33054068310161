import { createRouter, createWebHistory } from 'vue-router'
import { returnToCookieName } from 'utils/config'
import LzCookies from 'utils/lz_cookies'
import qs from 'qs'

export function scrollToTop(_to, _from, savedPosition) {
  if (savedPosition) return savedPosition
  return { left: 0, top: 0 }
}

const pathMatchesReturnToCookie = (path) => {
  const trailingSlash = /\/$/
  const cookiePath = decodeURI(LzCookies.get(returnToCookieName)).replace(trailingSlash, '')

  return path === cookiePath
}

export function stringifyQuery(query) {
  const result = qs.stringify(query, { arrayFormat: 'brackets', encode: false })

  return result ? result : ''
}

export function createLzRouter({ history, routes, ...rest } = {}) {
  const router = createRouter({
    history: history || createWebHistory(),
    parseQuery: qs.parse,
    stringifyQuery,
    routes,
    ...rest,
  })

  router.afterEach((to, from) => {
    if (pathMatchesReturnToCookie(from.fullPath)) {
      // If a return-to cookie has been set for this page, keep it updated as the route changes
      LzCookies.set(returnToCookieName, to.fullPath, { expires: 1, secure: true, sameSite: 'none' })
    }
  })

  return router
}
