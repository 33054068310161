<template>
  <span
    :class="chipClasses"
    class="flex cursor-default items-center space-x-1 rounded-2xl pl-3"
    data-test="chip"
    :data-test-color="color"
    :data-test-size="size"
  >
    <span :class="textClasses" class="font-bold leading-6" data-test="chip-label">{{ label }}</span>
    <button
      v-if="removable"
      :class="buttonClasses"
      class="flex h-6 w-6 cursor-pointer items-center rounded-full leading-6 transition-colors"
      type="button"
      :aria-label="$t('common.remove') + ' ' + `${label}`"
      data-test="remove-button"
      @click="$emit('remove')"
    >
      <LzIcon size="sm" path="icons/x--small" class="mx-auto" />
    </button>
  </span>
</template>

<script>
import { computed } from 'vue'
import LzIcon from './LzIcon'

const COLORS = ['primary', 'secondary', 'neutral']
const SIZES = ['sm', 'lg']

export default {
  name: 'Chip',
  components: { LzIcon },
  props: {
    label: { type: String, required: true },
    color: { type: String, default: COLORS[0], validator: (val) => COLORS.includes(val) },
    size: { type: String, default: SIZES[1], validator: (val) => SIZES.includes(val) },
  },
  setup(props, { attrs }) {
    const removable = computed(() => attrs.onRemove !== undefined)
    const small = computed(() => props.size === SIZES[0])
    const isPrimary = computed(() => props.color === COLORS[0])
    const isSecondary = computed(() => props.color === COLORS[1])
    const isNeutral = computed(() => props.color === COLORS[2])
    const chipClasses = computed(() => ({
      'pr-1': removable.value,
      'pr-3': !removable.value,
      'py-0': small.value,
      'py-1': !small.value,
      'bg-primary-100': isPrimary.value,
      'bg-secondary': isSecondary.value,
      'bg-gray-100': isNeutral.value,
    }))
    const textClasses = computed(() => ({
      'text-xs align-top my-0.5': small.value,
      'text-sm': !small.value,
      'text-primary-400': isPrimary.value,
      'text-base': isSecondary.value,
      'text-gray-600': isNeutral.value,
    }))
    const buttonClasses = computed(() => ({
      'text-primary-400': isPrimary.value,
      'text-base hover:text-base-hover hover:bg-secondary-hover': isSecondary.value,
      'text-accent hover:text-accent-hover': isNeutral.value,
    }))

    return { removable, small, chipClasses, textClasses, buttonClasses }
  },
}
</script>
