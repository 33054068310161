<template>
  <EmptyState :heading="heading" :body="body">
    <template #visual>
      <InlineSvg class="mx-auto my-0 w-1/2 max-w-md md:w-1/2" :path="path" />
    </template>
    <slot></slot>
  </EmptyState>
</template>

<script>
import EmptyState from './EmptyState'
import InlineSvg from '../InlineSvg'

export default {
  name: 'EmptyStateWithIcon',
  components: {
    EmptyState,
    InlineSvg,
  },
  props: {
    path: {
      type: String,
      required: true,
    },
    heading: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
  },
}
</script>
