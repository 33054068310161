<template>
  <div
    :class="[
      'flex items-center justify-center rounded-lg transition-colors duration-300',
      accordionSizeClasses,
      accordionColorClasses,
    ]"
  >
    <InlineSvg
      path="icons/next"
      :class="['inline-block transform transition-transform', svgSizeClasses, { 'rotate-90': isOpen }]"
    />
  </div>
</template>

<script>
import { computed } from 'vue'
import InlineSvg from './InlineSvg'

const SIZES = {
  lg: { div: 'h-8 w-8', svg: 'h-6 w-6' },
  md: { div: 'h-6 w-6', svg: 'h-5 w-5' },
}

export default {
  name: 'AccordionIcon',
  components: { InlineSvg },
  props: {
    isOpen: { type: Boolean, required: true },
    size: { type: String, default: Object.keys(SIZES)[0], validator: (value) => Object.keys(SIZES).includes(value) },
    accordionColorClasses: {
      type: String,
      default: 'group-hover:bg-base text-accent',
    },
  },
  setup(props) {
    return {
      accordionSizeClasses: computed(() => SIZES[props.size].div),
      svgSizeClasses: computed(() => SIZES[props.size].svg),
    }
  },
}
</script>
