// Any changes to this file should also be reflected in _javascript_head.html.erb

export let GOOGLE_RECAPTCHA_SITE_KEY = ''
export let DATADOG_RUM = {}
export let STATIC_HOST_URL = 'learnzillioncdn.com'
export let ZENDESK_BASE_URL = ''
export let CLEVER = { authenticatedUrl: 'https://clever.com/oauth/authenticated', clientId: 'd5772d6657ef4cfef3ec' }
export let MATHJAX_ASSET_URL =
  'https://static.learnzillioncdn.com/mathjax/2.7.1/MathJax.js?config=TeX-AMS-MML_HTMLorMML'
export const SPEECHSTREAM_URL = 'https://toolbar.speechstream.net/SpeechStream/3.9.5/speechstream.js'
export const SPEECHSTREAM_CONFIG = 'ILClassroomv395R1'
export let SHORT_DOMAIN = 'lzill.co'
export let NGIE_API_VERSION = 'v1'
export let NGIE_JS_API_URL = ''

export function initializeEnv(env) {
  GOOGLE_RECAPTCHA_SITE_KEY = env['GOOGLE_RECAPTCHA_SITE_KEY']
  DATADOG_RUM = env['DATADOG_RUM']
  STATIC_HOST_URL = env['STATIC_HOST_URL']
  ZENDESK_BASE_URL = env['ZENDESK_BASE_URL']
  SHORT_DOMAIN = env['SHORT_DOMAIN']
  CLEVER = env['CLEVER']
  MATHJAX_ASSET_URL = env['MATHJAX_ASSET_URL']
  NGIE_JS_API_URL = env['NGIE_JS_API_URL']
  NGIE_API_VERSION = env['NGIE_API_VERSION']
}
