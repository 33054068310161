import get from 'lodash/get'
import set from 'lodash/set'
import pick from 'lodash/pick'

/*
  These i18n helpers aid in creating a locale object for a specific set of strings.
  As conversions progress and features consolidate into each other, this will likely become less relevant.
*/

/*
  namespaceLocaleObj(messagesObject, 'namespace')
  Turns:
  {
    'en' { one: 'one', two: 'two' },
    'es-US': { one: '1', two: '2' }
  }
  Into:
  {
    'en' {
      namespace: { one: 'one', two: 'two' },
    },
    'es-US': {
      namespace: { one: '1', two: '2' }
    },
  }

  namespaceLocaleObj(messagesObject, 'namespace', { only: ['one', 'two'] })
  Turns:
  {
    'en' { one: 'one', two: 'two', three: 'three' },
    'es-US': { one: '1', two: '2', three: '3' }
  }
  Into:
  {
    'en' {
      namespace: { one: 'one', two: 'two' },
    },
    'es-US': {
      namespace: { one: '1', two: '2' }
    },
  }
*/
export function namespaceLocaleObj(localeObj, namespacePath, options = { only: '' }) {
  const locales = Object.keys(localeObj)
  return locales.reduce((obj, locale) => {
    const entries = options.only ? pick(localeObj[locale], options.only) : localeObj[locale]
    const rootPath = locale + (namespacePath ? `.${namespacePath}` : '')
    set(obj, rootPath, entries)
    return obj
  }, {})
}

/*
  elevateLocaleNamespace
  Turns:
  {
    'en' { namespace: { one: 'one' } },
    'es-US': { namespace: { one: 1 } },
  }
  Into:
  {
    'en' { one: 'one' },
    'es-US': { one: '1' },
  }

  options.key will group the results in their own namespace key
*/
export function elevateLocaleNamespace(localeObj, namespacePath, options = { key: '' }) {
  const locales = Object.keys(localeObj)
  return locales.reduce((obj, locale) => {
    const dataPath = `${locale}${options.key ? `.${options.key}` : ''}`
    set(obj, dataPath, get(localeObj[locale], namespacePath))
    return obj
  }, {})
}

/*
  pickLocaleNamespace
  Basically does a namespaceLocaleObj(elevateLocaleNamespace(...))
  but wrapping it into elevateLocaleNamespace is more performant.
*/
export function pickLocaleNamespace(localeObj, namespacePath) {
  const lastNamespace = namespacePath.split('.').pop()
  return elevateLocaleNamespace(localeObj, namespacePath, { key: lastNamespace })
}
