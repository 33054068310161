<!-- eslint-disable vuejs-accessibility/label-has-for  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <div class="flex flex-1 flex-col gap-1">
    <label class="mb-0 text-sm font-bold leading-none tracking-wide">{{ label }}</label>

    <DropdownPanel
      ref="panel"
      dropdown-placement="bottom-start"
      dropdown-class="flex flex-col py-6 rounded-2xl"
      :portal-name="portalName"
      :button-text="buttonText"
      :button-placeholder="$t('common.nothing_selected')"
      @toggle="onToggle"
    >
      <template #buttonContent="panelProps">
        <span class="sr-only">{{ label }}</span>
        <DropdownButton v-bind="panelProps" :class="buttonClass" />
      </template>

      <template #default="{ close }">
        <h3 class="m-0 text-2xl leading-8">{{ header }}</h3>
        <p class="text-muted text-sm leading-5">{{ subhead }}</p>
        <slot v-bind="{ close }" />
      </template>
    </DropdownPanel>
  </div>
</template>

<script>
import { ref, onMounted, inject } from 'vue'
import DropdownPanel from './DropdownPanel'
import DropdownButton from './DropdownButton'

const { buttonText, buttonPlaceholder } = DropdownPanel.props

export default {
  name: 'FilterDropdown',
  components: { DropdownPanel, DropdownButton },
  props: {
    buttonText,
    buttonPlaceholder,
    buttonClass: {
      type: String,
      default: '',
    },
    header: {
      type: String,
      required: true,
    },
    subhead: {
      type: String,
      required: true,
    },
    label: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    portalName: {
      type: String,
      default: 'root',
    },
  },
  setup(props, { emit }) {
    const panel = ref(null)
    const dropdownPanels = inject('dropdownPanels')
    const closeOtherPanels = inject('closeOtherPanels')
    const onToggle = (isOpen) => {
      if (isOpen) {
        closeOtherPanels(props.name)
      } else {
        emit('closed')
      }
    }

    onMounted(() => {
      dropdownPanels[props.name] = panel
    })

    return { panel, onToggle }
  },
}
</script>
