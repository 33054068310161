import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import { publicCustomerId } from 'utils/config'
import { SHORT_DOMAIN } from 'environment'
import { useCurrentUserStore } from './use_current_user_store'

export const useCurrentCustomerStore = defineStore('currentCustomer', () => {
  const state = reactive({
    id: null,
    authPreference: 'ilclassroom',
    authPreferenceOptions: {},
    districtId: '',
    hasClever: false,
    hasSingleIntegration: false,
    hasStudentFunctionality: true,
    isCustomerContentGated: true,
    isPublic: false,
    name: '',
    googleClassroomEnabled: false,
    subdomain: '',
    viewableAsTeacher: false,
    showStandards: true,
    domain: '',
    hasSessionTimeout: false,
    hasLmsIntegrations: false,
    standardInitiatives: [],
  })

  const isCleverCustomer = computed(() => state.hasClever)
  const isPublicCustomer = computed(() => state.id == publicCustomerId)
  const allowsGuestAccess = computed(() => !state.isCustomerContentGated)
  const isGuestContent = computed(() => (content) => hasGuestAccessEnabled.value && ownsContent.value(content))
  const hasGuestAccessEnabled = computed(() => !state.isCustomerContentGated)
  const ownsContent = computed(() => (content) => state.id == content.customerId)
  const isPublicContent = computed(() => (content) => isPublicCustomer.value && ownsContent.value(content))
  const isAuthClasslink = computed(() => state.authPreference === 'classlink')
  const isAuthClever = computed(() => state.authPreference === 'clever')
  const isAuthIlclassroom = computed(() => state.authPreference === 'ilclassroom')
  const isAuthOneRoster = computed(() => state.authPreference === 'oneroster')
  const enableContentFeatures = computed(() => {
    return (content) => {
      const { isLoggedIn } = useCurrentUserStore()
      return isLoggedIn.value || isPublicContent.value(content) || isGuestContent.value(content)
    }
  })
  const prefixedShortDomain = computed(() => {
    const subdomain = state.subdomain
    let prefix = ''
    if (subdomain.length && subdomain !== 'www') prefix = `${subdomain}.`
    return prefix + SHORT_DOMAIN
  })

  return {
    state,
    isCleverCustomer,
    isPublicCustomer,
    allowsGuestAccess,
    isGuestContent,
    hasGuestAccessEnabled,
    ownsContent,
    isPublicContent,
    isAuthClasslink,
    isAuthIlclassroom,
    isAuthClever,
    isAuthOneRoster,
    enableContentFeatures,
    shortDomain: prefixedShortDomain,
    publicCustomerId,
  }
})
