<template>
  <div data-test="lz-accordion" :class="{ 'border-base rounded-2xl border': bordered, open: open }">
    <slot v-bind="{ label, toggle, isOpen, verb }" name="head">
      <button
        data-test="accordion-head"
        :aria-expanded="isOpen ? 'true' : 'false'"
        class="text-primary-accent group flex w-full items-center justify-between p-3"
        @click="toggle"
      >
        <span class="flex items-center gap-2 font-bold">
          <AccordionIcon :is-open="isOpen" />
          {{ label }}
        </span>
        <span v-if="verbs" class="text-base text-sm">
          <span>{{ capitalize(verb) }}</span>
        </span>
      </button>
    </slot>
    <CollapseTransition>
      <slot :is-open="isOpen" name="body">
        <div
          v-show="isOpen"
          :class="['px-3 py-2 md:px-6', { 'rounded-b-2xl border-t': bordered }, colorClass]"
          data-test="accordion-body"
        >
          <slot v-bind="{ isOpen }" />
        </div>
      </slot>
    </CollapseTransition>
  </div>
</template>

<script setup>
import { ref, computed, watch } from 'vue'
import capitalize from 'lodash/capitalize'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { CollapseTransition } from 'vue_features/shared/components/transitions'
import { common } from 'vue_features/shared/i18n'
import AccordionIcon from './AccordionIcon'
import { useI18n } from 'vue-i18n'

const { t } = useI18n({ messages: namespaceLocaleObj(common, 'common', { only: ['hide', 'show'] }) })
const props = defineProps({
  label: { type: String, default: '' },
  open: { type: Boolean, default: false },
  verbs: { type: Boolean, default: false },
  color: {
    type: String,
    default: 'gray',
    validator: (value) => {
      return ['base', 'inverted', 'gray', 'focus'].indexOf(value) !== -1
    },
  },
  bordered: { type: Boolean, default: true },
})
const isOpen = ref(props.open)
const verb = computed(() => {
  return isOpen.value ? t('common.hide') : t('common.show')
})
const colorClass = computed(() => {
  switch (props.color) {
    case 'base':
      return 'border-base bg-base'
    case 'inverted':
      return 'border-inverted bg-inverted text-inverted'
    case 'focus':
      return 'border-base bg-focus'
    default:
      return 'border-base'
  }
})
watch(
  () => props.open,
  (newOpen) => {
    isOpen.value = newOpen
  },
)

const emit = defineEmits(['toggled'])
const toggle = () => {
  isOpen.value = !isOpen.value
  emit('toggled')
}
</script>
