<template>
  <form :action="path" @submit.prevent="runSearch">
    <SearchInput
      :id="id"
      v-model="queryInput"
      :label="$t('search.search')"
      :placeholder="placeholder || $t('search.search_or_code')"
      @click="runSearch"
    />
  </form>
</template>

<script>
import Routes from 'routes'
import URI from 'urijs'
import { location } from 'utils'
import { redirectedToLzCode } from '../api'
import { useSearchFiltersStore } from '../store/use_search_filters_store'
import SearchInput from './SearchInput'
import { ref, watch } from 'vue'

export default {
  name: 'SearchForm',
  components: { SearchInput },
  props: {
    placeholder: { type: String, default: null },
    id: { type: String, default: 'query' },
  },
  setup() {
    const { query } = useSearchFiltersStore()
    const queryInput = ref('')
    const path = Routes.search_path()
    const runSearch = async () => {
      // Check for LZ code redirect
      if (await redirectedToLzCode(queryInput.value)) return

      // Redirect to search page with query term if not already there
      if (location.current().pathname !== path) {
        const args = { path, query: { content_type: ['wiki', 'lesson_plan'] } }
        if (queryInput.value) args.query.q = queryInput.value
        location.assign(URI(args).toString())
        return
      }

      // Apply input value as current query term
      query.value = queryInput.value
    }

    watch(query, () => (queryInput.value = query.value), { immediate: true })

    return { path, queryInput, runSearch }
  },
}
</script>
