<script setup>
import { computed, ref } from 'vue'
import { useCopyToClipboard } from 'vue_features/shared/composables'
import LzButton from './LzButton'
import { useI18n } from 'vue-i18n'
import merge from 'lodash/merge'
import { namespaceLocaleObj, pickLocaleNamespace } from 'vue_features/shared/helpers/i18n_helper'
import { common, shared } from 'vue_features/shared/i18n'

defineProps({
  targetText: {
    type: String,
    required: true,
  },
})

const copyInput = ref(null)
const { copy: copyToClipboard, copied, error } = useCopyToClipboard(copyInput)
const buttonText = computed(() => {
  if (error.value) return errorMessage.value
  return copied.value ? 'copyable_input.copied' : 'common.copy'
})

const errorMessage = computed(() => {
  if (error.value == null) return null
  const osFamily = navigator.platform?.os?.family

  if (/OS X/i.test(osFamily)) return 'copyable_input.copy_error_mac'
  else if (/Android|iOS/i.test(osFamily)) return 'copyable_input.copy_error_mob'
  else return 'copyable_input.copy_error_pc'
})

const localeMessages = merge(
  {},
  namespaceLocaleObj(common, 'common', { only: 'copy' }),
  pickLocaleNamespace(shared, 'copyable_input'),
)
const { t } = useI18n({ messages: localeMessages })
</script>

<template>
  <div>
    <input
      ref="copyInput"
      aria-hidden="true"
      type="text"
      readonly
      class="absolute"
      style="left: -9999px"
      :value="targetText"
    />
    <LzButton color="primary" :disabled="error !== null" @click="copyToClipboard">
      {{ t(buttonText) }}
    </LzButton>
  </div>
</template>
