<template>
  <div
    class="bg-neutral-200 border-neutral-400 content flex flex-col flex-wrap gap-4 rounded-lg border p-4 md:flex-row md:align-middle"
  >
    <slot />
  </div>
</template>
<script>
import { reactive, provide } from 'vue'

export default {
  name: 'FilterBar',
  setup() {
    const dropdownPanels = reactive({})
    const closeOtherPanels = (currentPanel) => {
      Object.entries({ ...dropdownPanels }).forEach(([name, panel]) => {
        if (name !== currentPanel) {
          panel.value?.close()
        }
      })
    }
    provide('dropdownPanels', dropdownPanels)
    provide('closeOtherPanels', closeOtherPanels)
  },
}
</script>
