function preparePasswordResetForm() {
  document.querySelectorAll('form[action="/password_resets"]').forEach((form) => {
    form.addEventListener('submit', (event) => {
      const button = event.target.getElementsByClassName('btn--primary')[0]
      if (!button) return
      button.disabled = false
      button.classList.add('btn--updating')

      const icon = button.getElementsByClassName('icon')[0]
      if (!icon) return
      icon.classList.remove('hidden')
    })
  })
}

export default function () {
  preparePasswordResetForm()
}
