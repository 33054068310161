import {
  useGlobalStore,
  useCurrentUserStore,
  useCurrentCustomerStore,
  useFeatureFlagsStore,
} from 'vue_features/shared/store/composables'

export default function ({ state }) {
  const {
    currentUser: currentUserState,
    currentCustomer: currentCustomerState,
    featureFlags: featureFlagsState,
    ...globalState
  } = state
  globalState.isContentEmbedded = window && window.top !== window.self

  useGlobalStore().$set(globalState)
  useCurrentUserStore().$set(currentUserState)
  useCurrentCustomerStore().$set(currentCustomerState)
  useFeatureFlagsStore().$set(featureFlagsState)

  return {}
}
