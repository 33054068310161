<template>
  <img :src="avatarImage" class="h-80 max-h-full w-auto" :alt="t('avatar_img.title')" />
</template>

<script setup>
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { components } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { useI18n } from 'vue-i18n'
import merge from 'lodash/merge'
const messages = merge({}, namespaceLocaleObj(components, 'components', { only: ['avatar_img'] }))
const { t } = useI18n({ messages })
const { avatarImage } = useCurrentUserStore()
</script>
