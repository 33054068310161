<template>
  <EmptyState :heading="heading" :body="body">
    <template #visual>
      <div class="max-h-56 max-w-xs">
        <LottieAnimation ref="lottieAnimation" :data="animationLoader" :loop="loop" :autoplay="autoplay" />
      </div>
    </template>
    <slot></slot>
  </EmptyState>
</template>

<script setup>
import EmptyState from './EmptyState'
import { defineAsyncComponent } from 'vue'

const LottieAnimation = defineAsyncComponent(() =>
  import(/* webpackChunkName: "LottieAnimation" */ 'vue_features/shared/components/LottieAnimation'),
)

defineProps({
  animationLoader: {
    type: Function,
    required: true,
  },
  loop: {
    type: Boolean,
    default: false,
  },
  autoplay: {
    type: Boolean,
    default: false,
  },
  heading: {
    type: String,
    default: null,
  },
  body: {
    type: String,
    default: null,
  },
})
</script>
