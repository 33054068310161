<template>
  <div data-test="filter-lookup" class="relative">
    <SearchInput
      ref="inputRef"
      :label="$t('search.standards_lookup')"
      :placeholder="$t('search.search')"
      :model-value="term"
      @focus="listIsOpen = true"
      @blur="closeList"
      @update:modelValue="processTerm"
      @keydown.up.prevent="focusPreviousResult"
      @keydown.down.prevent="focusNextResult"
      @keydown.esc.prevent="blurInputIfEmpty"
    />
    <div
      v-show="listIsOpen"
      class="border-base bg-focus absolute z-10 max-h-48 w-full overflow-y-scroll rounded-2xl border text-xs"
      data-test="lookup-results"
    >
      <ul v-if="results.length" class="pl-0 text-xs">
        <li v-for="result in results" :key="result.key">
          <FilterLookupResult
            ref="resultRefs"
            :title="result.title"
            :description="result.description"
            class="border-base hover:bg-base-hover focus:bg-base-hover border-0 border-b"
            @keydown.up.prevent="focusPreviousResult"
            @keydown.down.prevent="focusNextResult"
            @keydown.esc.prevent="focusInput"
            @click="selectResult(result.key, result.title)"
          />
        </li>
      </ul>
      <div v-else class="px-3 py-2" data-test="search-prompt">{{ prompt }}</div>
    </div>
  </div>
</template>

<script>
import { ref } from 'vue'
import SearchInput from './SearchInput'
import FilterLookupResult from './FilterLookupResult'

export default {
  name: 'FilterLookup',
  components: { SearchInput, FilterLookupResult },
  props: {
    listApi: { type: Function, required: true },
    prompt: { type: String, default: '' },
  },
  setup(props, { emit }) {
    const term = ref('')
    const listIsOpen = ref(false)
    const results = ref([])
    const resultRefs = ref() // Element ref array for result buttons
    const inputRef = ref() // Component ref for input field

    const closeList = (e) => (listIsOpen.value = !!resultRefs.value?.find((result) => result.$el === e.relatedTarget))
    const processTerm = async (value) => {
      term.value = value
      listIsOpen.value = true
      results.value = await props.listApi(value)
    }
    const selectResult = (key, label) => {
      emit('select', { key, label })
      results.value = []
      listIsOpen.value = false
      term.value = ''
    }

    const focusInput = () => inputRef.value.focus()
    const focusPreviousResult = () => {
      if (!resultRefs.value) return // Nothing to do if no results rendered
      const index = _currentResultIndex()
      if (index === 0) return focusInput() // Top of list - go to input
      const prevIndex = index > 0 ? index - 1 : resultRefs.value.length - 1
      resultRefs.value[prevIndex].$el.focus()
    }
    const focusNextResult = () => {
      if (!resultRefs.value) return // Nothing to do if no results rendered
      const index = _currentResultIndex()
      if (index + 1 === resultRefs.value.length) return focusInput() // Bottom of list - go to input
      const nextIndex = index + 1
      resultRefs.value[nextIndex].$el.focus()
    }
    const blurInputIfEmpty = () => {
      if (!term.value) return inputRef.value.blur()
      term.value = ''
      results.value.splice(0, results.value.length)
    }
    const _currentResultIndex = () => resultRefs.value?.findIndex((result) => result.$el === document.activeElement)

    return {
      term,
      listIsOpen,
      results,
      resultRefs,
      inputRef,
      closeList,
      processTerm,
      selectResult,
      focusInput,
      focusPreviousResult,
      focusNextResult,
      blurInputIfEmpty,
    }
  },
}
</script>
