import get from 'lodash/get'

export function fixToolbarHiding(options) {
  if (get(options, 'sharedSpaces.top')) {
    const toolbarId = options.sharedSpaces.top
    document.querySelectorAll(`#${toolbarId} > div`).forEach((editor, index) => {
      editor.style.display = index === 0 ? 'block' : 'none'
    })
  }
}

export function ckeditorDialogsClosed() {
  return !![...document.querySelectorAll('.cke_dialog[role="presentation"], .lz-cke_dialog')].filter(
    (dialog) => dialog.style.visibility !== 'hidden',
  )
}
