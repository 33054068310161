<!-- eslint-disable vuejs-accessibility/mouse-events-have-key-events - keyboard users can view description with key events on parent -->
<!-- eslint-disable vuejs-accessibility/no-static-element-interactions  | TODO fix lint errors https://github.com/vue-a11y/eslint-plugin-vuejs-accessibility/tree/main/docs -->
<template>
  <button
    type="button"
    tabindex="0"
    class="inline-block w-full cursor-pointer px-3 py-2"
    data-test="lookup-result"
    @keydown.right.prevent="isExpanded = true"
    @keydown.left.prevent="isExpanded = false"
    @keydown="$emit('keydown', $event)"
    @click="$emit('click', $event)"
  >
    <div class="flex text-left">
      <div ref="textRef" :class="{ truncate: !isExpanded }" class="flex-grow" data-test="result-text">
        <span class="font-bold">{{ title }}:</span>
        {{ description }}
      </div>
      <div
        v-if="isLong"
        data-test="result-expander"
        :class="['w-3 flex-none', { 'text-white': isExpanded }]"
        @mouseenter="isExpanded = true"
        @mouseleave="isExpanded = false"
      >
        <LzIcon size="sm" :path="`icons/${isExpanded ? 'caret' : 'chevron-right'}`" />
      </div>
    </div>
  </button>
</template>

<script>
import scrolling from 'utils/scrolling'
import { ref, computed } from 'vue'
import { LzIcon } from 'vue_features/shared/components/ui'

export default {
  name: 'FilterLookupResult',
  components: { LzIcon },
  props: {
    title: { type: String, required: true },
    description: { type: String, required: true },
  },
  setup() {
    const textRef = ref(null) // Element ref so we can check overflow
    return { textRef, isExpanded: ref(false), isLong: computed(() => scrolling.overflowsWidth(textRef.value)) }
  },
}
</script>
