import { ref } from 'vue'

export default function useDismissibles() {
  const dismissibles = ref({})
  const storageId = (id) => `dismissible-${id}`

  function showDismissible(id, { limit = 1 } = {}) {
    const shownCount = parseInt(window.localStorage.getItem(storageId(id)) || 0)
    if (!limit || shownCount < limit) {
      dismissibles.value[id] = true
    }
  }

  function closeDismissible(id) {
    if (canDismissibleShow(id)) {
      const shownCount = parseInt(window.localStorage.getItem(storageId(id)) || 0)
      dismissibles.value[id] = false
      window.localStorage.setItem(storageId(id), shownCount + 1)
    }
  }

  function canDismissibleShow(id) {
    return !!dismissibles.value[id]
  }

  return { showDismissible, closeDismissible, canDismissibleShow }
}
