import { controllablePromise } from 'utils'

export default async function watchQuestionLoading() {
  const loadingPromises = []
  const observers = []
  const config = { childList: true, subtree: true }
  document.querySelectorAll('.nextgen-item').forEach((itemElement) => {
    const parent = itemElement.parentElement // learnosity removes our actual element
    const checkpoints = {
      addedLoader: false,
      removedLoader: false,
      addedContents: false,
    }

    const promise = controllablePromise()
    const callback = () => {
      if (!checkpoints.addedLoader) {
        checkpoints.addedLoader = !!parent.querySelector('#InlineLoader')
      } else if (checkpoints.addedLoader && !checkpoints.removedLoader) {
        checkpoints.removedLoader = !parent.querySelector('#InlineLoader')
      } else if (checkpoints.removedLoader && !checkpoints.addedContents) {
        checkpoints.addedContents = true
        promise.resolve()
      }
    }
    const observer = new MutationObserver(callback)
    observer.observe(parent, config)
    loadingPromises.push(promise)
    observers.push(observer)
  })

  // conservative bailout since ngie doesn't give us a real event
  window.setTimeout(() => {
    loadingPromises.forEach((p) => p.resolve())
    observers.forEach((o) => o.disconnect())
  }, 5_000)

  await Promise.all(loadingPromises)
  observers.forEach((o) => o.disconnect())
}
