// This ensures dynamic imports are loaded from the CDN (or whatever ASSET_HOST is set to).
// This overrides webpack's publicPath because we can only determine the value at run-time (not build time).
// eslint-disable-next-line no-undef, camelcase
__webpack_public_path__ = window.LZ.ENV.WEBPACK_PUBLIC_PATH

import '../sass/application.scss'
import '../css/application.css'

import 'initializers/immediate'
import 'initializers/vue_plugins'
import 'initializers/ckeditor_basepath'

export * from 'utils'

export { default as Routes } from 'routes'

import * as Mixins from 'mixins'
export { Mixins }

export * from 'clients'
export * from 'channels'

import * as analytics from 'initializers/analytics'
export { analytics }

export { onReadyPrint } from 'initializers/print'
export { onReadyApplication } from 'initializers/application'
export { onReadyLzuiFeature } from 'initializers/lzui_feature'
