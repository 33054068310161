<template>
  <ListDropdown
    id="user-actions"
    :button-aria="t('layouts.user_bar.my_account')"
    :button-classes="[
      'rounded-full',
      'flex',
      'justify-center',
      'items-center',
      'bg-neutral-200',
      'cursor-pointer',
      'text-important',
      'hover:bg-neutral-500',
      'transition-colors',
      'h-12',
      'w-12',
      'shadow-none',
      'border-none',
      'md:block',
      'hidden',
      'p-0',
    ]"
    :dropdown-classes="['right-0', 'left-auto']"
    class="hidden md:block"
  >
    <template #button>
      <AvatarIcon />
    </template>

    <template #listItems>
      <li>
        <button class="flex items-center space-x-3" @click="openTheme">
          <LzIcon path="icons/picture" size="md" />
          <span class="text-primary text-md font-semibold">{{ t('layouts.user_bar.themes') }}</span>
        </button>
      </li>
      <li>
        <button class="flex items-center space-x-3" @click="openAvatar">
          <LzIcon path="icons/user" size="md" />
          <span class="text-primary text-md font-semibold">{{ t('layouts.user_bar.avatars') }}</span>
        </button>
      </li>
      <li v-if="authorizedAsAdmin || authorizedAsSiteAdmin">
        <a class="flex items-center space-x-3" :href="Routes.site_admin_root_path()">
          <LzIcon path="icons/wrench" size="md" />
          <span class="text-primary text-md font-semibold">{{ t('common.admin') }}</span>
        </a>
      </li>
      <li v-if="authorizedAsCustomerAdmin">
        <a class="flex items-center space-x-3" :href="Routes.customers_url()">
          <LzIcon path="icons/rectangle-stack" size="md" />
          <span class="text-primary text-md font-semibold">{{ t('layouts.user_bar.customers') }}</span>
        </a>
      </li>
      <li>
        <a :href="Routes.edit_user_url()" class="flex items-center space-x-3">
          <LzIcon path="icons/cog" size="md" />
          <span class="text-primary text-md font-semibold">{{ t('layouts.user_bar.account_settings') }}</span>
        </a>
      </li>
      <li id="logout">
        <RailsForm :action="Routes.logout_path()" method="delete">
          <button
            type="submit"
            class="flex items-center space-x-2"
            data-test="logout-button"
            :data-logout-path="Routes.logout_path()"
          >
            <LzIcon path="icons/logout" size="md" />
            <span class="text-primary text-md font-semibold">{{ t('common.logout') }}</span>
          </button>
        </RailsForm>
      </li>
    </template>
  </ListDropdown>
</template>

<script setup>
import ListDropdown from 'vue_features/shared/components/ui/dropdowns/ListDropdown'
import AvatarIcon from 'vue_features/avatars/components/images/AvatarIcon'
import { openModal } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { LzIcon } from 'vue_features/shared/components/ui'
import Routes from 'routes'
import merge from 'lodash/merge'
import { common, layouts } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { useI18n } from 'vue-i18n'
import { RailsForm } from 'vue_features/shared/components/forms'
import { LzAnalytics } from '../../../clients'

const { authorizedAsAdmin, authorizedAsSiteAdmin, authorizedAsCustomerAdmin } = useCurrentUserStore()
const messages = merge(
  {},
  namespaceLocaleObj(common, 'common', { only: ['admin', 'logout'] }),
  namespaceLocaleObj(layouts, 'layouts', { only: ['user_bar'] }),
)
const { t } = useI18n({ messages })
const openTheme = () => {
  openModal('ThemeModal')
  trackAnalytics('Theme Modal')
}
const openAvatar = () => {
  openModal('AvatarModal')
  trackAnalytics('Avatar Modal')
}

const trackAnalytics = (eventName) => {
  LzAnalytics.track(eventName, { source: 'header', action: 'open' })
}
</script>
