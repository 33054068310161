<template>
  <div data-test="search-filters" class="mr-4 w-full flex-none space-y-2 sm:w-72">
    <div
      v-for="card in filterCards"
      :key="card.name"
      data-test="filter-card"
      :data-test-name="card.name"
      class="border-base bg-focus rounded-lg border p-4"
    >
      <button
        type="button"
        data-test="card-title"
        class="sm:text-md flex w-full cursor-pointer flex-row items-center space-x-1 text-left text-xs font-bold"
        @click="cardOpen[card.name].value = !cardOpen[card.name].value"
      >
        <LzIcon :path="`icons/${cardOpen[card.name].value ? 'caret' : 'chevron-right'}`" />
        <span>{{ $t(`search.filter_labels.${card.name}.title`) }}</span>
      </button>
      <div v-show="cardOpen[card.name].value" data-test="card-body" class="pt-4">
        <div v-if="card.filters" data-test="filter-group" class="relative space-y-2">
          <ul class="mb-0 list-none gap-1 space-y-4 pl-0 text-sm">
            <li
              v-for="filter in card.filters"
              :key="filter.name"
              data-test="group-filter"
              class="border-base space-y-2 border-0 border-t pt-4 first:border-none first:p-0"
            >
              <div data-test="group-title" class="text-xs font-bold sm:text-sm">
                {{ $t(`search.filter_labels.${filter.name}.title`) }}
              </div>
              <FilterPanel
                :name="filter.name"
                :wrap="filter.wrap"
                :toggle="filter.toggle"
                :lookup="filter.lookup"
                :scrollable="filter.scrollable"
              />
            </li>
          </ul>
        </div>
        <FilterPanel
          v-else
          :name="card.filter.name"
          :wrap="card.filter.wrap"
          :toggle="card.filter.toggle"
          :lookup="card.filter.lookup"
          :scrollable="card.filter.scrollable"
        />
      </div>
    </div>
  </div>
</template>

<script>
import FilterPanel from './FilterPanel'
import { LzIcon } from 'vue_features/shared/components/ui'
import { computed, ref } from 'vue'
import { useSearchFiltersStore } from 'vue_features/search/store/use_search_filters_store'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import screenSize from 'utils/screen_size'

export default {
  name: 'SearchFilters',
  components: { FilterPanel, LzIcon },
  props: {
    hiddenFilters: { type: Array, default: () => [] },
  },
  setup(props) {
    const { authorizedAsAuthor } = useCurrentUserStore()
    const { filterLayout, anyOptionChecked, getOptions } = useSearchFiltersStore()

    const cardOpen = {} // Keyed object to track filter card open states
    const _syncCardState = (cards) => {
      const punchlist = Object.fromEntries(cards.map((card) => [card.name, card]))

      // Delete open states for missing keys
      Object.keys(cardOpen).forEach((key) => {
        if (!punchlist[key]) delete cardOpen[key]
      })

      // Create + update open states for all card keys
      Object.values(punchlist).forEach((card) => (cardOpen[card.name] = ref(card.isOpen)))
      return cards
    }

    const _filterCardItems = () =>
      _visibleFiltersOrGroups(filterLayout).map((filterOrGroup) => {
        const name = filterOrGroup.name

        // Return card item for single filter
        if (!filterOrGroup.filters) return { name, filter: filterOrGroup, isOpen: anyOptionChecked(name) }

        // Get visible filter list and return card item for group
        const filters = _visibleFiltersOrGroups(filterOrGroup.filters)
        return { name, filters, isOpen: !!filters.find((filter) => anyOptionChecked(filter.name)) }
      })
    const _visibleFiltersOrGroups = (items) => {
      const allowedItems = authorizedAsAuthor.value ? items : items.filter((item) => !item.authorOnly)
      return allowedItems.filter((item) => (item.filters || [item]).find((filter) => _filterVisible(filter)))
    }
    const _filterVisible = (filter) => {
      if (props.hiddenFilters.includes(filter.name)) return false
      if (filter.lookup) return true
      const opts = getOptions(filter.name)
      return !!opts && (opts.length > 1 || opts[0]?.checked)
    }

    return {
      cardOpen,
      isXs: screenSize.isXs,
      filterCards: computed(() => _syncCardState(_filterCardItems())),
    }
  },
}
</script>
