import { onMounted, onUnmounted } from 'vue'

export default function useEventListener(type, listener, options = {}) {
  onMounted(() => {
    window.addEventListener(type, listener, options)
  })
  onUnmounted(() => {
    window.removeEventListener(type, listener, options)
  })
}
