<template>
  <div :class="classesForState">
    <div class="border-primary-200 text-primary flex-grow overflow-hidden truncate text-left text-sm leading-6">
      {{ buttonText || buttonPlaceholder }}
    </div>
    <div class="text-primary group-hover:text-accent flex items-center" ratio:class="isOpen && 'border-primary-accent'">
      <LzIcon :path="isOpen ? 'icons/caret-reverse' : 'icons/caret'" />
    </div>
  </div>
</template>

<script>
import { computed } from 'vue'
import LzIcon from './LzIcon'

export default {
  name: 'DropdownButton',
  components: { LzIcon },
  props: {
    buttonText: {
      type: String,
      required: true,
    },
    buttonPlaceholder: {
      type: String,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const classesForState = computed(() =>
      [
        'border-primary-200',
        'pointer',
        'shadow-button',
        'bg-neutral-100',
        'hover:border-primary-accent',
        'hover:shadow-button-active',
        'flex',
        'group',
        'items-center',
        'justify-between',
        'rounded-lg',
        'border',
        'border-solid',
        'text-sm',
        'transition-all',
        'duration-200',
        'p-2',
        'space-x-2',
        props.isOpen && ['border-primary-accent', 'bg-neutral-50', 'shadow-button-active'],
      ].flat(),
    )

    return { classesForState }
  },
}
</script>

<style scoped>
div:hover + div {
  border-color: rgb(var(--primary-accent)) !important;
}
</style>
