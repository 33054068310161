<template>
  <div class="border-base inline-flex h-10 items-stretch overflow-hidden rounded-xl border bg-white p-1">
    <slot></slot>
  </div>
</template>

<script>
import { computed, provide } from 'vue'

export default {
  name: 'ToggleButton',
  props: {
    name: {
      type: String,
      default: null,
    },
    value: {
      type: [Array, String, Object, Number],
      default: null,
    },
  },
  setup(props, { emit }) {
    const selectedValue = computed(() => props.value)

    const select = (value) => {
      emit('input', value)
    }

    provide('name', props.name)
    provide('select', select)
    provide('selectedValue', selectedValue)
  },
}
</script>
