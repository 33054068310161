import * as focusTrap from 'focus-trap'
import { watch, onBeforeUnmount } from 'vue'

const useFocusTrap = (
  trapRef,
  autoStart = true,
  options = { escapeDeactivates: false, clickOutsideDeactivates: true },
) => {
  let trap

  if (autoStart) {
    watch(trapRef, (element) => {
      if (element) {
        startFocus(element)
      } else {
        stopFocus()
      }
    })

    onBeforeUnmount(() => {
      stopFocus()
    })
  }

  const startFocus = (element) => {
    try {
      trap = focusTrap.createFocusTrap(element, options)
      trap.activate()
    } catch {
      // testing fails unnecessarily without catch
    }
  }

  const stopFocus = () => {
    if (trap) {
      trap.deactivate()
      trap = null
    }
  }
  const pauseFocus = () => {
    if (trap) {
      trap.pause()
    }
  }

  const unpauseFocus = () => {
    if (trap) {
      trap.unpause()
    }
  }

  return {
    startFocus,
    stopFocus,
    pauseFocus,
    unpauseFocus,
  }
}

export default useFocusTrap
