<template>
  <div :class="{ loading: !isResolved }">
    <slot v-if="isResolved" />
    <slot v-else name="loadingContent">
      <LoadingSpinner />
    </slot>
  </div>
</template>

<script>
/* eslint-disable vue/component-api-style */
import LoadingSpinner from '../LoadingSpinner'
import { logAndNotify } from 'utils/log_and_notify'

export default {
  name: 'AsyncContentLoader',
  components: { LoadingSpinner },

  props: {
    loader: {
      type: [Promise, Function],
      default: null,
    },
  },

  data() {
    return {
      isResolved: true,
    }
  },

  watch: {
    loader: {
      immediate: true,
      async handler(promiseOrFunction) {
        if (!promiseOrFunction) return

        const promise = typeof promiseOrFunction === 'function' ? promiseOrFunction() : promiseOrFunction
        this.isResolved = false
        try {
          await promise
          this.isResolved = true
        } catch (error) {
          logAndNotify(error)
        }
      },
    },
  },
}
</script>
