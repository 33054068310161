<template>
  <button
    :class="buttonClasses"
    class="min-w-max rounded-lg px-4 text-sm font-bold leading-5 transition-colors"
    :aria-checked="isSelected"
    role="radio"
    @click="select(value)"
  >
    <slot></slot>
  </button>
</template>

<script>
import { inject, computed } from 'vue'

const baseClasses = ['text-muted']
const hoverClasses = ['hover:bg-focus-hover hover:border-base-hover hover:text-important-hover']
const selectedClasses = ['text-white', 'bg-accent']

export default {
  name: 'ToggleButtonOption',
  props: {
    value: {
      type: [Array, String, Object, Number],
      default: null,
    },
    compareWith: {
      type: Function,
      default: (a, b) => a === b,
    },
  },
  setup(props) {
    const name = inject('name')
    const select = inject('select')
    const selectedValue = inject('selectedValue')
    const isSelected = computed(() => props.compareWith(props.value, selectedValue.value))

    const buttonClasses = computed(() => {
      return [
        ...(!isSelected.value ? baseClasses : []),
        ...(!isSelected.value ? hoverClasses : []),
        ...(isSelected.value ? selectedClasses : []),
      ]
    })

    return {
      name,
      isSelected,
      select,
      buttonClasses,
    }
  },
}
</script>

<style scoped>
:not(:first-child) {
  @apply rounded-l-none border-l-0;
}

:not(:last-child) {
  @apply rounded-r-none;
}
</style>
