<template>
  <div data-test="filter-panel" class="relative space-y-2">
    <ul
      ref="listRef"
      v-observe-visibility="displayArrow"
      data-test="option-list"
      :class="listClasses"
      class="list-none gap-1 text-sm"
      @scroll="updateArrow"
    >
      <li v-for="opt in options" :key="opt.filterKey">
        <Chip
          v-if="lookup"
          data-test="option-chip"
          size="sm"
          color="neutral"
          :label="opt.title"
          @remove="removeOption(opt.filterName, opt.filterKey)"
        />
        <LzTooltip
          v-else-if="toggle"
          data-test="option-switch"
          :title="opt.title"
          tag="span"
          size="xs"
          tooltip-classes="bg-yellow-50"
        >
          <button
            :class="{
              'border-transparent bg-blue-700 text-white': opt.checked,
              'border-base hover:border-base-hover hover:bg-base-hover hover:text-base-hover bg-white': !opt.checked,
            }"
            class="h-8 w-8 cursor-pointer rounded border border-solid px-1 text-center"
            type="button"
            data-test="switch-button"
            @click="setOption(opt.filterName, opt.filterKey, !opt.checked)"
          >
            {{ $t(`search.filter_labels.${opt.filterName}.abbreviations.${opt.filterKey}`) }}
          </button>
        </LzTooltip>
        <div v-else data-test="option-check" class="flex space-x-1 sm:space-x-2">
          <label
            :for="`option_${opt.filterName}_${opt.filterKey}`"
            data-test="check-label"
            class="mb-0 flex cursor-pointer space-x-1 text-xs sm:space-x-2 sm:text-sm"
          >
            <input
              :id="`option_${opt.filterName}_${opt.filterKey}`"
              type="checkbox"
              class="mt-0.5 h-4 w-4 flex-none cursor-pointer"
              :checked="opt.checked"
              @change="setOption(opt.filterName, opt.filterKey, !opt.checked)"
            />
            <div class="flex space-x-1 sm:space-x-2">
              <FilterSubpanel v-if="opt.subfilterName" :name="opt.subfilterName" />

              <img v-if="!!opt.icon" alt="" :src="opt.icon" data-test="check-icon" class="mt-0.5 h-4 w-4 flex-none" />
              <span>{{ opt.title }}</span>
            </div>
          </label>
        </div>
      </li>
    </ul>
    <FilterLookup
      v-if="lookup"
      :prompt="$t(`search.prompts.${lookup.filterName}`)"
      :list-api="lookup.listApi"
      @select="addOption(lookup.filterName, { filterKey: $event.key, label: $event.label, checked: true })"
    />
    <div
      v-if="scrollable"
      v-show="showArrow"
      data-test="more-options-arrow"
      class="center absolute -bottom-1 left-0 right-0 h-2"
    >
      <LzIcon size="sm" path="icons/caret" />
    </div>
  </div>
</template>

<script>
import scrolling from 'utils/scrolling'
import { ref, computed, onUpdated } from 'vue'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { useSearchFiltersStore } from '../store/use_search_filters_store'
import { Chip, LzIcon, LzTooltip } from 'vue_features/shared/components/ui'
import FilterSubpanel from './FilterSubpanel'
import FilterLookup from './FilterLookup'
import { useI18n } from 'vue-i18n'

export default {
  name: 'FilterPanel',
  components: { Chip, LzIcon, LzTooltip, FilterSubpanel, FilterLookup },
  props: {
    name: { type: String, required: true },
    wrap: { type: Boolean, default: false },
    toggle: { type: Boolean, default: false },
    lookup: { type: Object, default: undefined },
    scrollable: { type: Boolean, default: false },
  },
  setup(props) {
    const { authorizedAsAuthor } = useCurrentUserStore()
    const { getOptions, addOption, setOption, removeOption } = useSearchFiltersStore()

    const listRef = ref(null) // Element ref so we can check scroll position
    const listClasses = {
      'py-1 max-h-80 overflow-y-scroll border-0 border-b border-t border-base': props.scrollable,
      'flex flex-row flex-wrap': props.wrap,
      'space-y-2': !props.wrap,
    }
    const { t } = useI18n()
    const _addTitles = (options) => {
      return options.map((opt) => {
        const title =
          (opt.label || t(`search.filter_labels.${opt.filterName}.${opt.filterKey}`)) +
          (opt.count ? ` (${opt.count})` : '')
        return Object.assign({ title }, opt)
      })
    }
    const options = computed(() => _addTitles(getOptions(props.name, !authorizedAsAuthor.value)))

    // If the filter is scrollable, we update the more arrow visibility in three cases:
    // * When the list gets scrolled
    // * When the list is opened and displayed (via `displayArrow`)
    // * When the list changes size (via `onUpdated`)
    const showArrow = ref(props.scrollable)
    const updateArrow = props.scrollable ? () => (showArrow.value = scrolling.canScrollDown(listRef.value)) : () => {}
    const displayArrow = props.scrollable ? (isVisible) => isVisible && updateArrow() : () => {}
    if (props.scrollable) onUpdated(updateArrow)

    return { listRef, listClasses, options, showArrow, updateArrow, displayArrow, addOption, setOption, removeOption }
  },
}
</script>
