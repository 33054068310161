import { ref, computed, onMounted, watch } from 'vue'
import useElementDimensions from './use_element_dimensions'
import debug from 'debug'

const log = debug('app:vue_features/shared/composables/use_svg_view_box')

export default function useSvgViewBox({ container, initialWidth, initialHeight }) {
  const containerWidth = ref(initialWidth.value)
  const containerHeight = ref(initialHeight.value)
  const { width: elementWidth, height: elementHeight } = useElementDimensions(container)
  const viewBox = computed(() => `0 0 ${containerWidth.value} ${containerHeight.value}`)

  onMounted(() => {
    containerWidth.value = elementWidth.value || initialWidth.value
    containerHeight.value = elementHeight.value || initialHeight.value
  })

  watch(
    [container, elementWidth, elementHeight],
    () => {
      log('Element dimensions changed: %s x %s', elementWidth.value, elementHeight.value)
      if (!container.value) {
        containerWidth.value = initialWidth.value
        containerHeight.value = initialHeight.value
        return
      }

      containerWidth.value = elementWidth.value || initialWidth.value || 0
      containerHeight.value = elementHeight.value || initialHeight.value || 0
    },
    { immediate: true },
  )

  return { viewBox, containerWidth, containerHeight }
}
