<template>
  <ul class="lzui-nav-tabs vue-tabs">
    <RoutingLink :link="firstLink" :is-active="isActive(firstLink)" />
    <RoutingLink v-if="!hasOverflowLinks && links[1]" :link="links[1]" :is-active="isActive(links[1])" />
    <li v-if="hasOverflowLinks" class="lzui-nav-tabs__overflow">
      <button class="font-weight-bold lzui-nav-tabs__overflow__toggle" type="button" @click="toggleOverflow">
        {{ $t('common.more') }} +
      </button>
      <ul :class="{ is_open: isOverflowVisible }" class="lzui-nav-tabs__overflow__tabs">
        <RoutingLink v-for="link in overflowLinks" :key="link.label" :link="link" :is-active="isActive(link)" />
      </ul>
    </li>
  </ul>
</template>

<script setup>
import every from 'lodash/every'
import some from 'lodash/some'
import RoutingLink from './RoutingLink'
import { ref, computed, watch } from 'vue'
import { useRoute } from 'vue-router'

const props = defineProps({
  links: {
    type: Array,
    required: true,
    validator(links) {
      function hasRequiredProps(link) {
        return (link.path || link.name) && link.label
      }

      return every(links, hasRequiredProps)
    },
  },
})

const isOverflowVisible = ref(false)
const noMatchingPath = ref(false)
const firstLink = computed(() => props.links[0])
const hasOverflowLinks = computed(() => props.links.length > 2)
const overflowLinks = computed(() => props.links.slice(1))
const route = useRoute()
watch(
  () => route.name,
  () => {
    noMatchingPath.value = !some(
      props.links,
      (link) => link.path === route.path || (link.name && link.name === route.name),
    )
  },
  { immediate: true },
)

const toggleOverflow = () => {
  isOverflowVisible.value = !isOverflowVisible.value
}
const isActive = (link) => {
  return route.name === link.name || !!(noMatchingPath.value && link.default)
}
</script>
