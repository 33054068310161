import LzAnalytics from 'clients/analytics'
import debug from 'debug'

const log = debug('app:clients/analytics')

export function onReady(params) {
  LzAnalytics.initialize(params).then(() => {
    if (LzAnalytics.increment_visits) {
      LzAnalytics.increment('Visit count', 1)
    }

    LzAnalytics.clear_residual_traits()

    if (LzAnalytics.logged_in) {
      LzAnalytics.identify(LzAnalytics.current_user_id, LzAnalytics.current_user_traits)
    }

    LzAnalytics.page()
    initClickTracking()
  })
}

function initClickTracking() {
  // Initialize analytics trackers from data attributes in ERB templates:
  document.querySelectorAll('[data-track-click]').forEach((el) => {
    let { trackClick: eventName, once, preventDefault, eventProps = '{}' } = el.dataset
    once = Boolean(once)
    preventDefault = Boolean(preventDefault)

    log('bind analytics click handler on %O', el)

    try {
      eventProps = JSON.parse(eventProps)
    } catch (error) {
      log('error parsing custom event properties from data attribute on %O: %O', el, error)
    }

    el.addEventListener(
      'click',
      (evt) => {
        if (preventDefault) evt.preventDefault()
        LzAnalytics.track(eventName, eventProps, { sendBeacon: !preventDefault })
      },
      { passive: !preventDefault, capture: false, once },
    )
  })
}
