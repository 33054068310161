import FILTERS from './filters'
import { getStandards, getStandardLabel } from '../api'

export default function generateFilters() {
  const filterConfigs = {} // Static filter configurations (though dynamically generated from definitions)
  const optionLists = {} // Changing lists of per-filter options that drive component display (goes into state)
  const selectedOptions = {} // Lists of per-filter selected option keys that drive data loading (goes into state)
  const restrictingFilters = [] // List of filter names with restrictions to apply
  const restrictedFilters = [] // List of filters restricted by other options

  const _convertConfig = (name, config, loaded) => {
    // Convert filter config object for operation and special keys to expanded form
    const obj = Object.assign({ name, loaded }, config)
    if (config.superfilter) obj.superfilter = _toNameKey(config.superfilter)
    if (config.restrictions) obj.restrictions = _convertRestrictions(name, config.restrictions)
    if (config.lookup) obj.lookup = _convertLookup(config.lookup)
    return obj
  }
  const _convertRestrictions = (filterName, restrictions) => {
    const map = restrictions.split(',').map(_toNameKey)
    map.forEach(({ name }) => restrictedFilters.includes(name) || restrictedFilters.push(name))
    restrictingFilters.push(filterName)
    return map
  }
  const _convertLookup = (filterName) => {
    const obj = { filterName }
    if (filterName === 'standards') {
      // Assign API methods
      obj.listApi = (query) => getStandards(query)
      obj.labelApi = (uuid) => getStandardLabel(uuid)
    }
    return obj
  }
  const _toNameKey = (namekey) => {
    const [name, key] = namekey.split(':')
    return { name, key }
  }

  // Shared with the store for converting new dynamic options
  const convertOption = (filterName, opt) => {
    // Convert strings to objects, set defaults, and force checked to boolean
    const obj = typeof opt === 'string' ? { filterKey: opt } : Object.assign({}, opt)
    obj.filterName = filterName
    obj.checked = !!obj.checked
    obj.count ||= ''
    return obj
  }

  // Generate filters and indices from filter config file
  FILTERS.forEach(({ name, config = {}, options = [] }) => {
    // Assign converted config, options, and empty checked state for each filter
    filterConfigs[name] = _convertConfig(name, config, options.length > 0)
    const convertedOpts = options.map((opt) => convertOption(name, opt))
    optionLists[name] = convertedOpts
    selectedOptions[name] = []
  })

  return { filterConfigs, restrictingFilters, restrictedFilters, optionLists, selectedOptions, convertOption }
}
