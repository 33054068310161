<template>
  <div v-if="isActive && !isContentEmbedded" v-once class="banner center bg-orange-50 border-orange-200 p-1">
    <small class="bold">{{ $t('session.read_only_message') }}</small>
  </div>
</template>

<script>
import { onMounted, onUnmounted } from 'vue'
import { useGlobalStore } from 'vue_features/shared/store/composables'
import { HTTP } from 'vue_features/shared/helpers/http_helper'

export default {
  name: 'PermissionsNotice',
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { isContentEmbedded } = useGlobalStore()
    const gateAjax = (_, options) => {
      if (options.method && options.method !== 'GET') return true
    }
    if (props.isActive) {
      onMounted(() => HTTP.addFilter(gateAjax))
      onUnmounted(() => HTTP.removeFilter(gateAjax))
    }

    return { isContentEmbedded }
  },
}
</script>
