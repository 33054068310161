import { reactive, computed } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import UserService from 'vue_features/users_edit/api/user'
import { themeArray, avatarArray } from 'vue_features/themes/shared/utils/images'

function hasPermissionsFor(state, role) {
  return state.authorizedRoles[role] || false
}

export const useCurrentUserStore = defineStore('currentUser', () => {
  const state = reactive({
    id: null,
    name: null,
    email: null,
    role: null,
    authorizedRoles: {},
    canEditResource: null,
    hasPassword: null,
    googleClassroomLinked: null,
    impersonated: false,
    gradeLevel: null,
    school: null,
    showStandardsPrefix: true,
    goPeerToken: null,
    goPeerTutorToken: null,
    goPeerEnv: null,
    avatar: null,
    theme: null,
  })

  const isLoggedIn = computed(() => state.id > 0)
  const isGuest = computed(() => state.role === 'anon')
  const isStudent = computed(() => state.role === 'student')
  const isTeacher = computed(() => state.role === 'teacher')
  const authorizedAsAuthor = computed(() => hasPermissionsFor(state, 'author'))
  const authorizedAsTeacher = computed(() => hasPermissionsFor(state, 'teacher'))
  const authorizedAsSiteAdmin = computed(() => hasPermissionsFor(state, 'siteAdmin'))
  const authorizedAsAdmin = computed(() => hasPermissionsFor(state, 'admin'))
  const authorizedAsCustomerAdmin = computed(() => hasPermissionsFor(state, 'multiSiteAdmin'))

  const setAvatar = (avatar) => {
    state.avatar = avatar
    UserService.update({ avatar: state.avatar })
  }

  const setTheme = (theme) => {
    state.theme = theme
    UserService.update({ theme: theme })
  }

  const gradeBand = computed(() =>
    state.gradeLevel && (state.gradeLevel === 'k' || state.gradeLevel < 6) ? 'k5' : '612',
  )

  const themes = computed(() => themeArray[gradeBand.value])

  const themeImages = computed(() => themes.value[state.theme ? state.theme : 'default'])

  const getThemeImage = (type) => themeImages.value?.[type] ?? null

  const avatars = computed(() => avatarArray[gradeBand.value])

  const avatarImage = computed(
    () => avatarArray?.[gradeBand.value]?.[state.avatar]?.[state.theme ? state.theme : 'base'] ?? null,
  )

  return {
    state,
    isLoggedIn,
    isGuest,
    isStudent,
    isTeacher,
    authorizedAsAuthor,
    authorizedAsTeacher,
    authorizedAsSiteAdmin,
    authorizedAsAdmin,
    authorizedAsCustomerAdmin,
    setAvatar,
    setTheme,
    gradeBand,
    themes,
    getThemeImage,
    avatars,
    avatarImage,
  }
})
