import { getBreakStyle, hasContent, isFlexRow, isGrid, removeEmptyParents } from './dom'
import renderFlexingItem from './render_flexing_item'
import { isPdf, renderPdf } from './render_pdf'
import { createPage, isOverflowing, moveBreakAfterAvoids, renderElement } from './page'
import breakPage from './break_page'

export default async function pager() {
  addRefs()
  await chunk()
}

function addRefs() {
  const elements = document.querySelector('#un-formatted').querySelectorAll('*')
  elements.forEach((el) => addRef(el))
}

function addRef(element) {
  element.setAttribute('data-ref', getDataRef())
}

function chunk() {
  return renderChildren(document.querySelector('#un-formatted'), [], [createPage()])
}

async function renderChildren(element, parentChain = [], pages) {
  const newParentChain = [...parentChain]
  if (element.id !== 'un-formatted') {
    newParentChain.push(element)
  }
  for (const child of Array.from(element.children)) {
    if (getBreakStyle(child).before === 'always') {
      pages.push(createPage())
    }
    if (isPdf(child)) {
      await renderPdf(child, newParentChain, pages)
    } else if (isGrid(child)) {
      await renderGrid(child, newParentChain, pages)
    } else if (isFlexRow(child)) {
      await renderFlexingItem(child, newParentChain, pages)
    } else if (
      !hasContent(child) &&
      !element.classList.contains('math-tex') &&
      !element.classList.contains('MathJax')
    ) {
      await renderChildren(child, newParentChain, pages)
    } else {
      let page = pages[pages.length - 1]
      let lastRenderedElement = await renderElement(child, newParentChain, page)
      let tries = 0
      while (isOverflowing(page) && !lastRenderedElement.getAttribute('data-unbreakable') && tries < 100) {
        const { newChunk, newPage } = breakPage(lastRenderedElement, newParentChain, page)
        lastRenderedElement = newChunk
        pages.push(newPage)
        page = newPage
        tries++
      }
    }
  }
}

async function renderGrid(gridItem, parentChain, pages) {
  const page = pages[pages.length - 1]
  const renderedGrid = await renderElement(gridItem, parentChain, page)
  if (isOverflowing(page)) {
    const oldParent = renderedGrid.parentElement
    const { newPage } = breakPage(renderedGrid, parentChain, page)
    removeEmptyParents(oldParent)
    moveBreakAfterAvoids(page, newPage)
    pages.push(newPage)
  }
}

let PRINT_ID = 1
function getDataRef() {
  return `ele-${PRINT_ID++}`
}
