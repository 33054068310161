import { ref } from 'vue'

export default function useAsync(promise) {
  const error = ref(null)
  const data = ref(null)

  // Argument may be a function. Call and wrap it to ensure that its return value is always thenable:
  promise = promise instanceof Function ? Promise.resolve(promise()) : promise

  promise
    .then((res) => {
      data.value = res
    })
    .catch((err) => {
      error.value = err
    })

  return { data, error }
}
