import { ref } from 'vue'
import { getPlaceholder } from './use_content_type_metadata'

// Beware: This is being spied on globally in the test suite (in vue_spec_init).
export default function useThumbnailUrl(thumbnail, type = null) {
  const startingValue = thumbnail || (type ? getPlaceholder(type) : thumbnail)
  const thumbnailUrl = ref(startingValue)

  if (thumbnailUrl.value.endsWith('.svg')) {
    thumbnailUrl.value = ''
    import(
      /* webpackChunkName: '[request]' */
      /* webpackInclude: /\.svg$/ */
      `images/icons/${startingValue}`
    ).then(({ default: svg }) => {
      thumbnailUrl.value = `"data:image/svg+xml,${encodeURIComponent(svg)}"`
    })
  }

  return { thumbnailUrl }
}
