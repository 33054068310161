import constant from 'lodash/constant'
import filter from 'lodash/filter'
import forEach from 'lodash/forEach'
import get from 'lodash/get'
import isNil from 'lodash/isNil'
import method from 'lodash/method'

import $script from 'scriptjs'

import debug from 'debug'
const log = debug('app:wistia_api')

const wistiaApiUrl = 'https://fast.wistia.net/assets/external/E-v1.js'

export function pauseAllVideos() {
  return pauseVideos(allVideos())
}

// from http://wistia.com/doc/player-api#pause_other_videos_when_another_is_played
export function pauseOtherVideos(video) {
  const videos = filter(allVideos(), (other) => video.hashedId() !== other.hashedId())
  return pauseVideos(videos)
}

export function pushOntoQueue(videoIdOrPayload, handler) {
  // Load the Wistia API external script. The _wq queue will be handled by the
  // included script once it loads and evaluates, so there is no need for the
  // $script.ready callback here.
  $script(wistiaApiUrl, 'wistia')

  window._wq = window._wq || []
  let payload
  if (isNil(handler)) {
    payload = videoIdOrPayload
  } else {
    payload = {}
    payload[videoIdOrPayload] = handler
  }
  window._wq.push(payload)
}

function pauseVideos(videos) {
  return forEach(videos, method('pause'))
}

function client() {
  if (window.Wistia === undefined) {
    log('Attempted to call Wistia api before Wistia script loaded.')
    return nullWistia
  }

  return window.Wistia.api
}

export function allVideos() {
  return client().all()
}

export function name() {
  return get(client(), '__name__', constant('Wistia'))()
}

const nullWistia = {
  all: constant([]),
  __name__: constant('NullWistia'),
}
