const listenersByEl = new Map()

export default function useNamespacedEventListener(el) {
  if (!el) throw Error('You must pass in a target element to attach event listeners (i.e. window or document).')

  function eventName(type) {
    return type.split('.')[0]
  }

  function eventNames(type) {
    return type.split(',').map((t) => t.trim())
  }

  function listenersForEl() {
    if (!listenersByEl.has(el)) listenersByEl.set(el, {})
    return listenersByEl.get(el)
  }

  function on(type, listener, options = {}) {
    eventNames(type).forEach((event) => {
      if (listenersForEl()[event]) throw new Error(`Already listening to ${event}!`)
      listenersForEl()[event] = listener
      el.addEventListener(eventName(event), listenersForEl()[event], options)
    })
  }

  function off(type) {
    eventNames(type).forEach((event) => {
      el.removeEventListener(eventName(event), listenersForEl()[event])
      delete listenersForEl()[event]
    })
  }

  function allOff() {
    Object.keys(listenersForEl()).forEach((eventType) => off(eventType))
    listenersByEl.delete(el)
  }

  return { on, off, allOff }
}

export function allElementsOff() {
  for (const el of listenersByEl.keys()) {
    useNamespacedEventListener(el).allOff()
  }
}
