<script>
import { h } from 'vue'
import RoutingMenu from './RoutingMenu'
import TabMenu from './TabMenu'

export default function LzTabs(props, context) {
  const tabComponent = props.links?.length ? RoutingMenu : TabMenu
  return h(tabComponent, context.attrs, context.slots)
}
</script>
