import { onUnmounted, ref, watch } from 'vue'
import get from 'lodash/get'

export default function useElementDimensions(elementRef) {
  const width = ref(null)
  const height = ref(null)
  const observer = new ResizeObserver((entries) => {
    for (const entry of entries) {
      const { contentRect } = entry
      width.value = contentRect.width
      height.value = contentRect.height
    }
  })

  onUnmounted(() => {
    observer.disconnect()
  })

  watch(
    elementRef,
    (newVal, oldVal) => {
      if (oldVal) {
        observer.disconnect()
      }

      if (newVal) {
        width.value = get(newVal, 'clientWidth', 0)
        height.value = get(newVal, 'clientHeight', 0)
        observer.observe(newVal)
      }
    },
    {
      immediate: true,
    },
  )

  return { width, height }
}
