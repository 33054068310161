import { createLzRouter } from 'vue_features/shared/router'
import { searchFormMessages as messages } from './i18n'
import routes from './routes'
import SearchForm from './components/SearchForm'
import { location as $location } from 'utils'

export default function () {
  // stops dev warning on every non-search page that route match does not exist
  const adjustedRoutes = [...routes]
  adjustedRoutes.push({ path: $location.pathname(), component: SearchForm })

  const router = createLzRouter({ routes: adjustedRoutes })
  return { component: SearchForm, messages, router }
}
