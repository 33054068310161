import { unref } from 'vue'

export const REVISION = 'revision'
export const ARCHIVED = 'archived'
export const DRAFT = 'draft'
export const ACTIVE = 'active'

export function isState(recordOrState, state) {
  const rawVal = unref(recordOrState)
  return [rawVal, rawVal?.state].includes(state)
}

export function isArchived(recordOrState) {
  return isState(recordOrState, ARCHIVED)
}

export function isDraft(recordOrState) {
  return isState(recordOrState, DRAFT)
}

export function isRevision(recordOrState) {
  return isState(recordOrState, REVISION)
}

export function isActive(recordOrState) {
  return isState(recordOrState, ACTIVE)
}
