<template>
  <div data-test="active-filters" class="flex flex-row flex-wrap items-center gap-2">
    <Chip
      v-for="chip in activeChips"
      :key="chip.key"
      :label="chip.label.value"
      color="secondary"
      class="flex-none"
      @remove="removeChip(chip.filter, chip.key)"
    />
    <button
      v-if="activeChips.length > 0"
      type="button"
      data-test="clear-active"
      class="text-accent hover:text-accent-hover flex-none cursor-pointer py-2 text-sm"
      @click="clearFilters"
    >
      {{ $t('search.clear_all') }}
    </button>
  </div>
</template>

<script setup>
import Chip from 'vue_features/shared/components/ui/Chip'
import { computed } from 'vue'
import { useSearchFiltersStore } from 'vue_features/search/store/use_search_filters_store'
import { useI18n } from 'vue-i18n'

const { setOption, getOption, selectedOptions, query, clearFilters, QUERY_KEY } = useSearchFiltersStore()

const removeChip = (filterName, filterKey) => {
  if (filterKey === QUERY_KEY) query.value = ''
  else setOption(filterName, filterKey, false)
}
const { t } = useI18n()
const activeChips = computed(() => {
  const initialChips = query.value ? [{ key: QUERY_KEY, label: query }] : []

  return Object.entries(selectedOptions.value).reduce((chips, [filter, keys]) => {
    return chips.concat(
      keys.map((key) => {
        const opt = getOption(filter, key)
        const name = opt?.label || t(`search.filter_labels.${filter}.${key}`)
        const label = computed(() => (opt?.master ? name + t('search.filter_labels.all') : name))
        return { filter, key, label }
      }),
    )
  }, initialChips)
})
</script>
