<template>
  <form accept-charset="UTF-8" :action="action" :method="formMethod">
    <input v-if="addRailsMethod" name="_method" :value="method" type="hidden" />
    <input value="✓" type="hidden" name="utf8" />
    <input :value="authenticityToken" type="hidden" name="authenticity_token" />

    <slot />
  </form>
</template>

<script>
import { computed } from 'vue'
import { metaTags } from 'utils'

export default {
  name: 'RailsForm',
  props: {
    action: {
      type: String,
      required: true,
    },
    method: {
      type: String,
      required: true,
      validator(value) {
        return ['delete', 'get', 'patch', 'post', 'put'].includes(value)
      },
    },
  },
  setup(props) {
    const addRailsMethod = computed(() => props.method !== 'get' && props.method !== 'post')
    const formMethod = computed(() => (addRailsMethod.value ? 'post' : props.method))

    return {
      addRailsMethod,
      authenticityToken: metaTags.csrfToken(),
      formMethod,
    }
  },
}
</script>
