function namespaceSvgIdsAndClasses(sourceSvg, namespace = crypto.randomUUID()) {
  const svg = sourceSvg.cloneNode(true)
  svg.id = `root-${namespace}${svg.id ? `-${svg.id}` : ''}`
  const replacedIds = namespaceIds(svg, namespace)
  const replacedClasses = namespaceClasses(svg, svg.id)

  const svgWithNamespacedIds = namespaceIdReferences(svg, namespace, replacedIds)
  const svgWithNamespacedClasses = namespaceClassReferences(svgWithNamespacedIds, replacedClasses)
  return svgWithNamespacedClasses
}

function namespaceIds(svg, namespace) {
  const oldIds = []

  new Array(...svg.querySelectorAll('*[id]')).forEach((element) => {
    oldIds.push(element.id)
    element.id = `${namespace}-${element.id}`
  })

  return oldIds
}

function namespaceClasses(svg, rootId) {
  const replacementClasses = []

  svg.querySelectorAll('style').forEach((e) => {
    e.innerHTML
      .split('\n')
      .map((l) => extractClassName(l))
      .filter((l) => l !== null)
      .map((l) => {
        return l[1].trim()
      })
      .forEach((item) =>
        replacementClasses.push({
          oldClass: item,
          newClass: `#${rootId} ${item}`,
        }),
      )
  })

  return replacementClasses
}

function namespaceIdReferences(svg, namespace, replacedIds) {
  let content = svg.innerHTML
  replacedIds.forEach((id) => {
    const namespacedID = `${namespace}-${id}`
    content = content
      .replace(new RegExp(`url\\(#${id}\\)`, 'g'), `url(#${namespacedID})`)
      .replace(new RegExp(`href="#${id}"`, 'g'), `href="#${namespacedID}"`)
  })

  svg.innerHTML = content
  return svg
}

function namespaceClassReferences(svg, replacedClasses) {
  let updatedContent = svg.innerHTML
  replacedClasses.forEach((pairing) => {
    updatedContent = updatedContent.replace(pairing.oldClass, pairing.newClass)
  })
  svg.innerHTML = updatedContent
  return svg
}

function extractClassName(line) {
  return line.match(/^\s*(\..*)\{/)
}

function sanitizeSvg(sourceSvg) {
  const svg = sourceSvg.cloneNode(true)
  svg.querySelectorAll('script').forEach((e) => e.remove())
  return svg
}

export { namespaceSvgIdsAndClasses, sanitizeSvg }
