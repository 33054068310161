<template>
  <span class="relative inline-block">
    <LzIcon data-test="badged-icon" :path="path" size="md" :class="iconClasses" />
    <span
      v-if="badge"
      data-test="icon-badge"
      :class="badgeClasses"
      class="absolute -right-1 -top-1 flex h-4 w-4 items-center justify-center rounded-xl border border-white text-xs"
    >
      {{ badge }}
    </span>
  </span>
</template>

<script>
import LzIcon from './LzIcon'

export default {
  name: 'IconBadge',
  components: { LzIcon },
  props: {
    badge: { type: String, default: '' },
    path: { type: String, default: 'icons/list' },
    iconClasses: { type: [String, Array, Object], default: 'text-base' },
    badgeClasses: { type: [String, Array, Object], default: 'bg-neutral-900 text-white' },
  },
}
</script>
