<template>
  <span :class="{ 'text-accent': isCurrent, gap: isTruncated }" class="page">
    <template v-if="isCurrent || isTruncated">
      <span class="sr-only">{{ $t('common.page') }}</span>
      {{ page }}
    </template>
    <template v-else>
      <button type="button" class="hover:text-accent cursor-pointer p-2 text-base" @click.stop="getPage(page)">
        <span class="sr-only">{{ $t('common.page') }}</span>
        {{ page }}
      </button>
    </template>
  </span>
</template>

<script>
/* eslint-disable vue/component-api-style */
export default {
  props: {
    page: {
      type: String,
      required: true,
    },
    currentPage: {
      type: String,
      required: true,
    },
  },
  computed: {
    isCurrent() {
      return this.page === this.currentPage
    },
    isTruncated() {
      return isNaN(this.page)
    },
  },
  methods: {
    getPage(page) {
      this.$emit('page-changed', page)
    },
  },
}
</script>
