<template>
  <div class="mx-auto flex max-w-prose flex-col items-center justify-center p-6 text-center">
    <slot name="visual"></slot>
    <div class="mt-2">
      <h2 v-if="heading" data-focus-anchor tabindex="0" class="my-0" data-test="empty-state-heading">{{ heading }}</h2>
      <p v-if="body" class="text-muted mb-0 mt-2" data-test="empty-state-body">
        {{ body }}
      </p>
    </div>
    <div class="mt-4">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmptyState',
  props: {
    heading: {
      type: String,
      default: null,
    },
    body: {
      type: String,
      default: null,
    },
  },
}
</script>
