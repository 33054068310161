import { ref, computed, unref } from 'vue'

import { logAndNotify } from 'utils/log_and_notify'

export default function useCopyToClipboard(source) {
  const isInFrame = window.self != window.top
  const isSourceElement = computed(() => unref(source) instanceof Element)

  const copied = ref(false)
  const error = ref(null)

  const sendError = (errorMessage) => {
    error.value = errorMessage
    alert(errorMessage)
    logAndNotify(errorMessage)
  }

  const sendSuccess = () => {
    copied.value = true
    error.value = null
  }

  const copy = () => {
    const sourceText = isSourceElement.value ? source.value.value : source.value
    if (isInFrame) {
      if (!isSourceElement.value)
        throw new Error('Failed to copy. Source must be an element when using inside an iframe.')
      legacyCopy(source.value)
    } else if (navigator.clipboard)
      navigator.clipboard
        .writeText(sourceText)
        .then(sendSuccess)
        .catch(() => sendError('Failed to copy. navigator.clipboard could not be used.'))
    else {
      const input = document.createElement('input')
      input.value = sourceText
      input.type = 'text'
      document.body.appendChild(input)
      legacyCopy(input)
      input.remove()
    }
  }

  const legacyCopy = (inputEl) => {
    try {
      inputEl.select()
      const successful = document.execCommand('copy')
      if (successful) sendSuccess()
      else sendError('Failed to copy. document.execCommand was not successful.')
    } catch (_) {
      sendError('Failed to copy. Error while calling document.execCommand')
    }
  }

  return { copy, copied, error }
}
