import { DEPRECATED_RESOURCE_TYPES } from 'rails_constants'
import { computed, unref } from 'vue'
import { isResourceOfType } from './use_resource_types'

function cardContent(card) {
  return unref(card)?.content
}

export function cardContentType(card) {
  return cardContent(card)?.type
}

function isCardOfType(card, type) {
  if (['TitleCard', 'ResourceCard'].includes(type) && unref(card)?.type === type) return true
  return isResourceOfType(cardContent(card), type)
}

export function isQuestionCard(card) {
  return isCardOfType(card, 'LearnosityItem')
}

export function isTitleCard(card) {
  return isCardOfType(card, 'TitleCard')
}

export function isSlideCard(card) {
  return isCardOfType(card, 'Slide')
}

export function isResourceCard(card) {
  return isCardOfType(card, 'ResourceCard')
}

export function isWikiCard(card) {
  return isCardOfType(card, 'Wiki')
}

export function hasPersistedResource(card) {
  return isResourceCard(card) && !!cardContent(card)?.id
}

export function useCardTypes(cardRef) {
  const cardContentTypeRef = computed(() => cardContentType(cardRef))
  const isDeprecatedResourceTypeCard = computed(() => DEPRECATED_RESOURCE_TYPES.includes(cardContentTypeRef.value))

  return {
    isQuestionCard: computed(() => isQuestionCard(cardRef)),
    isTitleCard: computed(() => isTitleCard(cardRef)),
    contentType: cardContentTypeRef,
    isDeprecatedResourceTypeCard,
  }
}
