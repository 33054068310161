import * as vueFeatures from 'vue_features'

export function loadFeature(name) {
  switch (name) {
    case 'DataDashboardFeature':
      return import(
        /* webpackChunkName: "data-dashboard-feature" */ 'vue_features/data_dashboard/data_dashboard_feature.js'
      )
    case 'ItemAnalysisFeature':
      return import(
        /* webpackChunkName: "item-analysis-feature" */ 'vue_features/item_analysis/item_analysis_feature.js'
      )
    case 'LessonPlanShowFeature':
      return import(
        /* webpackChunkName: "lesson-plan-show-feature" */ 'vue_features/lesson_plans/show/lesson_plan_show_feature.js'
      )
    case 'LessonPlanGatedShowFeature':
      return import(
        /* webpackChunkName: "lesson-plan-gated-show-feature" */ 'vue_features/lesson_plans/show/lesson_plan_gated_show_feature.js'
      )
    case 'AssignmentShowFeature':
      return import(
        /* webpackChunkName: "assignment-show-feature" */ 'vue_features/assignments/show/assignment_show_feature.js'
      )
    case 'AssignmentGatedShowFeature':
      return import(
        /* webpackChunkName: "assignment-gated-show-feature" */ 'vue_features/assignments/show/assignment_gated_show_feature.js'
      )
    case 'LessonPlanPreviewFeature':
      return import(
        /* webpackChunkName: "lesson-plan-preview-feature" */ 'vue_features/lesson_plans/preview/lesson_plan_preview_feature.js'
      )
    case 'GradingFeature':
      return import(/* webpackChunkName: "grading-feature" */ 'vue_features/grading/grading_feature.js')
    case 'BookmarksIndexFeature':
      return import(
        /* webpackChunkName: "bookmarks-index-feature" */ 'vue_features/bookmarks_index/bookmarks_index_feature.js'
      )
    case 'TroubleshooterFeature':
      return import(
        /* webpackChunkName: "troubleshooter-feature" */ 'vue_features/troubleshooter/troubleshooter_feature.js'
      )
    case 'CollectionsIndexFeature':
      return import(
        /* webpackChunkName: "collections-index-feature" */ 'vue_features/collections_index/collections_index_feature.js'
      )
    case 'CustomersIndexFeature':
      return import(
        /* webpackChunkName: "customers-index-feature" */ 'vue_features/customers_index/customers_index_feature.js'
      )
    case 'UsersEditFeature':
      return import(/* webpackChunkName: "users-edit-feature" */ 'vue_features/users_edit/users_edit_feature.js')
    case 'SiteAdminCustomersFeature':
      return import(
        /* webpackChunkName: "site-admin-customers-feature" */ 'vue_features/site_admin/customers/site_admin_customers_feature.js'
      )
    case 'KlassesIndexFeature':
      return import(
        /* webpackChunkName: "klasses-index-feature" */ 'vue_features/klasses/index/klasses_index_feature.js'
      )
    case 'KlassesShowFeature':
      return import(/* webpackChunkName: "klasses-show-feature" */ 'vue_features/klasses/show/klasses_show_feature.js')
    case 'SiteAdminCleverFeature':
      return import(
        /* webpackChunkName: "site-admin-clever-feature" */ 'vue_features/site_admin/clever/site_admin_clever_feature.js'
      )
    case 'QuestionBanksFeature':
      return import(
        /* webpackChunkName: "question-banks-feature" */ 'vue_features/question_banks/question_banks_feature.js'
      )
    case 'ContactFormFeature':
      return import(/* webpackChunkName: "contact-form-feature" */ 'vue_features/contact_form/contact_form_feature.js')
    case 'ContactFormPageFeature':
      return import(
        /* webpackChunkName: "contact-form-page-feature" */ 'vue_features/contact_form_page/contact_form_page_feature.js'
      )
    case 'LMSIntegrationsFeature':
      return import(
        /* webpackChunkName: "lms-integrations-feature" */ 'vue_features/lms_integrations/lms_integrations_feature.js'
      )
    case 'SiteAdminSchoolsFeature':
      return import(
        /* webpackChunkName: "site-admin-schools-feature" */ 'vue_features/site_admin/schools/site_admin_schools_feature.js'
      )
    case 'UsersIndexFeature':
      return import(
        /* webpackChunkName: "users-index-feature" */ 'vue_features/site_admin/users/users_index_feature.js'
      )
    case 'InfoFormFeature':
      return import(/* webpackChunkName: "info-form-feature" */ 'vue_features/lesson_plans/info_form_feature.js')
    case 'SessionsNewFeature':
      return import(/* webpackChunkName: "sessions-new-feature" */ 'vue_features/sessions/sessions_new_feature.js')
    case 'AdminAccessesIndexFeature':
      return import(
        /* webpackChunkName: "admin-accesses-index-feature" */ 'vue_features/site_admin/admin_accesses/admin_accesses_index_feature.js'
      )
    case 'LessonPlanEditFeature':
      return import(
        /* webpackChunkName: "lesson-plan-edit-feature" */ 'vue_features/lesson_plans/edit/lesson_plan_edit_feature.js'
      )
    case 'WikiEditsIndexFeature':
      return import(
        /* webpackChunkName: "wiki-edits-index-feature" */ 'vue_features/wiki_edits/wiki_edits_index_feature.js'
      )
    case 'LiveLearnFeature':
      return import(/* webpackChunkName: "live-learn-feature" */ 'vue_features/live_learn/live_learn_feature.js')
    case 'SiteAdminUserImportsFeature':
      return import(
        /* webpackChunkName: "site-admin-user-imports-feature" */ 'vue_features/site_admin/customers/site_admin_user_imports_feature.js'
      )
    case 'SearchIndexFeature':
      return import(/* webpackChunkName: "search-index-feature" */ 'vue_features/search/search_index_feature.js')
    case 'LessonPlanPresenterFeature':
      return import(
        /* webpackChunkName: "lesson-plan-presenter-feature" */ 'vue_features/lesson_plans/presenter/lesson_plan_presenter_feature.js'
      )
    case 'ResourceFormFeature':
      return import(/* webpackChunkName: "resource-form-feature" */ 'vue_features/resources/resource_form_feature.js')
    case 'ResourceShowFeature':
      return import(/* webpackChunkName: "resource-show-feature" */ 'vue_features/resources/resource_show_feature.js')
    case 'ResourceGatedShowFeature':
      return import(
        /* webpackChunkName: "resource-gated-show-feature" */ 'vue_features/resources/resource_gated_show_feature.js'
      )
    case 'FeedbackAccordionFeature':
      return import(
        /* webpackChunkName: "feedback-accordion-feature" */ 'vue_features/contact_form/feedback_accordion_feature.js'
      )
    case 'ContentItemsIndexFeature':
      return import(
        /* webpackChunkName: "content-items-index-feature" */ 'vue_features/lti/content_items_index_feature.js'
      )
    case 'ContentItemsV13IndexFeature':
      return import(
        /* webpackChunkName: "content-items-v13-index-feature" */ 'vue_features/lti/content_items_v13_index_feature.js'
      )
    case 'CustomerSelectsFeature':
      return import(
        /* webpackChunkName: "customer-selects-feature" */ 'vue_features/customer_selects/customer_selects_feature.js'
      )
    case 'SiteAdminSingleIntegrationFeature':
      return import(
        /* webpackChunkName: "site-admin-single-integration-feature" */ 'vue_features/site_admin/single_integration/site_admin_single_integration_feature.js'
      )
    case 'SiteAdminSisIntegrationFeature':
      return import(
        /* webpackChunkName: "site-admin-sis-integration-feature" */ 'vue_features/site_admin/sis_integration/site_admin_sis_integration_feature.js'
      )
    case 'StandardsCoverageTableFeature':
      return import(
        /* webpackChunkName: "standards-coverage-table-feature" */ 'vue_features/standards_coverage_table/standards_coverage_table_feature.js'
      )
    case 'SiteAdminStandardsFeature':
      return import(
        /* webpackChunkName: "site-admin-standards-feature" */ 'vue_features/site_admin/standards/site_admin_standards_feature.js'
      )
  }
  return Promise.resolve({ default: vueFeatures[name] })
}
