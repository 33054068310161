<template>
  <div
    v-if="avatarImage"
    class="lg:max-h-120 pointer-events-none absolute bottom-0 right-0 flex h-full max-h-36 w-full justify-end overflow-hidden md:max-h-96"
  >
    <img
      :src="avatarImage"
      class="h-full w-auto origin-top-right transform"
      :class="[gradeBand === '612' ? 'scale-150' : 'translate-y-12 scale-110']"
      :alt="t('components.avatar_img.title')"
    />
  </div>
</template>

<script setup>
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { components } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { useI18n } from 'vue-i18n'
import merge from 'lodash/merge'
const messages = merge({}, namespaceLocaleObj(components, 'components', { only: ['avatar_img'] }))
const { t } = useI18n({ messages })
const { gradeBand, avatarImage } = useCurrentUserStore()
</script>
