let stack = []
let stackPosition = -1

const UndoStack = {
  undo() {
    if (stack[stackPosition]) {
      stack[stackPosition] = stack[stackPosition]()
      stackPosition--
    }
  },

  redo() {
    if (stack[stackPosition]) {
      stackPosition++
      stack[stackPosition] = stack[stackPosition]()
    }
  },

  track(action) {
    this.clearRedo()
    stackPosition++
    stack[stackPosition] = action
  },

  clearRedo() {
    stack = stack.slice(0, stackPosition + 1)
  },
}

export default UndoStack
