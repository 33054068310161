<template>
  <div
    v-if="avatarImage"
    class="absolute left-0 top-0 h-full w-full overflow-hidden rounded-full bg-top"
    :style="{ backgroundImage: `url(${avatarImage})`, backgroundSize: gradeBand === '612' ? '175%' : '167%' }"
  />
  <InlineSvg v-else path="icons/user" class="h-full w-full p-2" />
</template>

<script setup>
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
const { gradeBand, avatarImage } = useCurrentUserStore()
</script>
