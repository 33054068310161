<template>
  <a target="_blank" class="group inline-flex items-center gap-2">
    <slot />
    <LzIcon
      path="icons/external"
      size="sm"
      class="text-muted group-hover:text-accent flex-shrink-0 opacity-50 transition group-hover:opacity-100"
    />
  </a>
</template>

<script>
import LzIcon from './LzIcon'

export default {
  name: 'ExternalLink',
  components: { LzIcon },
}
</script>
