<template>
  <div v-if="visible" class="loading loading-spinner__overlay">
    <div class="loading__message">
      <LoadingSpinner style="width: inherit" />
      <p>{{ message === undefined ? t('progress.please_wait') : message }}</p>
    </div>
  </div>
</template>

<script setup>
import LoadingSpinner from 'vue_features/shared/components/ui/LoadingSpinner'
import { components } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { useI18n } from 'vue-i18n'

defineProps({
  visible: {
    type: Boolean,
    default: false,
  },
  message: {
    type: String,
    default: undefined,
  },
})
const { t } = useI18n({ messages: namespaceLocaleObj(components, '', { only: 'progress' }) })
</script>
