<template>
  <ul class="block shadow-none md:hidden">
    <li class="mx-1 text-sm">
      <button class="p-2.5" @click="openTheme">{{ t('layouts.user_bar.themes') }}</button>
    </li>
    <li class="mx-1 text-sm">
      <button class="p-2.5" @click="openAvatar">{{ t('layouts.user_bar.avatars') }}</button>
    </li>
    <li v-if="authorizedAsAdmin || authorizedAsSiteAdmin" class="mx-1 text-sm">
      <a :href="Routes.site_admin_root_path()" class="block p-2.5 text-base">
        {{ t('common.admin') }}
      </a>
    </li>
    <li v-if="authorizedAsCustomerAdmin" class="text-primary mx-1 text-sm">
      <a :href="Routes.customers_url()" class="block p-2.5 text-base">
        {{ t('layouts.user_bar.customers') }}
      </a>
    </li>
    <li class="mx-1 text-sm">
      <a :href="Routes.edit_user_url()" class="block p-2.5 text-base">
        {{ t('layouts.user_bar.account_settings') }}
      </a>
    </li>
    <li id="logout-mobile" class="mx-1 text-sm">
      <RailsForm :action="Routes.logout_path()" method="delete">
        <button type="submit" data-test="logout-button" :data-logout-path="Routes.logout_path()" class="block p-2.5">
          {{ t('common.logout') }}
        </button>
      </RailsForm>
    </li>
  </ul>
</template>

<script setup>
import { openModal } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import Routes from 'routes'
import merge from 'lodash/merge'
import { common, layouts } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { useI18n } from 'vue-i18n'
import { RailsForm } from 'vue_features/shared/components/forms'

const { authorizedAsAdmin, authorizedAsSiteAdmin, authorizedAsCustomerAdmin } = useCurrentUserStore()
const messages = merge(
  {},
  namespaceLocaleObj(common, 'common', { only: ['admin', 'logout'] }),
  namespaceLocaleObj(layouts, 'layouts', { only: ['user_bar'] }),
)
const { t } = useI18n({ messages })
const openTheme = () => {
  openModal('ThemeModal')
}
const openAvatar = () => {
  openModal('AvatarModal')
}
</script>
