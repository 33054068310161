class Signal {
  constructor() {
    this.listeners = []
  }

  onEmit(listener) {
    this.listeners.push(listener)
  }

  stop(listenerToRemove) {
    this.listeners = this.listeners.filter((listener) => listener !== listenerToRemove)
  }

  emit() {
    for (const listener of this.listeners) {
      listener.call(this)
    }
  }
}

export default Signal
