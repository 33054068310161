// Responsive breakpoints defined by Tailwind
const SM_BREAK = 640
const MD_BREAK = 768
const LG_BREAK = 1024
const XL_BREAK = 1280
const XXL_BREAK = 1536

const screenSize = {
  isXs() {
    return window.innerWidth < SM_BREAK
  },

  isSm() {
    return window.innerWidth >= SM_BREAK && window.innerWidth < MD_BREAK
  },

  isMd() {
    return window.innerWidth >= MD_BREAK && window.innerWidth < LG_BREAK
  },

  isLg() {
    return window.innerWidth >= LG_BREAK && window.innerWidth < XL_BREAK
  },

  isXl() {
    return window.innerWidth >= XL_BREAK && window.innerWidth < XXL_BREAK
  },

  isXxl() {
    return window.innerWidth >= XXL_BREAK
  },

  BREAKPOINTS: { sm: SM_BREAK, md: MD_BREAK, lg: LG_BREAK, xl: XL_BREAK, xxl: XXL_BREAK },
}

export default screenSize
