<template>
  <div data-test="filter-subpanel" class="w-4 space-y-2 overflow-x-visible">
    <button
      data-test="expand-button"
      :aria-label="$t('search.expand_button')"
      type="button"
      class="text-muted h-5 w-5 cursor-pointer"
      @click="isOpen = !isOpen"
    >
      <LzIcon size="sm" :path="`icons/${isOpen ? 'caret' : 'chevron-right'}`" />
    </button>
    <FilterPanel
      v-show="isOpen"
      class="w-min pb-2 sm:w-56"
      :name="subfilter.name"
      :wrap="subfilter.wrap"
      :toggle="subfilter.toggle"
      :lookup="subfilter.lookup"
      :scrollable="subfilter.scrollable"
    />
  </div>
</template>

<script setup>
import { LzIcon } from 'vue_features/shared/components/ui'
import { defineAsyncComponent, ref } from 'vue'
import { useSearchFiltersStore } from 'vue_features/search/store/use_search_filters_store'

const props = defineProps({ name: { type: String, required: true } })
const FilterPanel = defineAsyncComponent(() => import('./FilterPanel'))
const { anyOptionChecked, getFilterConfig } = useSearchFiltersStore()
const isOpen = ref(anyOptionChecked(props.name))
const subfilter = getFilterConfig(props.name)
</script>
