import isNil from 'lodash/isNil'
import isString from 'lodash/isString'

export function toBoolean(val) {
  return !!(!isNil(val) && val.toString().trim() !== 'false' && val !== 0)
}

export function jsonStringify(val) {
  return isString(val) ? val : JSON.stringify(val)
}

export function parseJson(val) {
  return isString(val) ? JSON.parse(val) : val
}

// Replacement for jQuery's deprecated isNumeric static function:
export function isNumeric(val) {
  return !isNaN(parseFloat(val)) && isFinite(val)
}

/*
 * Converts a number to a string with language specific placement of commas, decimal points, and optionally
 * currency symbols.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat
 */
export function formatNumberForLocale(val, locale = 'en-US', options = {}) {
  return new Intl.NumberFormat(locale, options).format(val)
}
