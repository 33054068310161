import $script from 'scriptjs'
import { apiVersion, apiURL, isEnabled } from './config'

const ngieItemsUrl = `${apiURL}/${apiVersion}/itemsAPI-loader-nocache.js`
const ngieAuthorUrl = `${apiURL}/${apiVersion}/author-loader-nocache.js`

let itemsAppLoaded = null
let authorAppLoaded = null

export function loadItemsAppScript() {
  if (!isEnabled() && !itemsAppLoaded) {
    itemsAppLoaded = Promise.resolve()
  }

  if (itemsAppLoaded) {
    return itemsAppLoaded
  }

  $script(ngieItemsUrl, 'ngie-items-app-script')

  itemsAppLoaded = new Promise((resolve) => {
    $script.ready('ngie-items-app-script', resolve)
  })

  return itemsAppLoaded
}

export function resetItemsAppScript() {
  itemsAppLoaded = null
}

export function loadAuthorAppScript() {
  if (!isEnabled() && !authorAppLoaded) {
    authorAppLoaded = Promise.resolve()
  }

  if (authorAppLoaded) {
    return authorAppLoaded
  }

  $script(ngieAuthorUrl, 'ngie-author-app-script')

  authorAppLoaded = new Promise((resolve) => {
    $script.ready('ngie-author-app-script', resolve)
  })

  return authorAppLoaded
}

export function resetAuthorAppScript() {
  authorAppLoaded = null
}
