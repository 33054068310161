import Routes from 'routes'
import { HTTP } from 'vue_features/shared/helpers/http_helper'
import { metaTags, location } from 'utils'
import { lzCode } from 'utils/config'

const CODE_REGEX = new RegExp(...lzCode.code.regexp)

let controller = null
const getResults = async (params) => {
  if (controller) {
    controller.abort()
  }
  controller = new AbortController()

  // Build POST body - always specify which ES indices (`m`odels) to use and ask for aggregate search data
  const body = new URLSearchParams()
  Object.entries(params).forEach(([key, value]) => [].concat(value).forEach((item) => body.append(key, item)))
  if (!params.m) body.append('m', 'LessonPlan,Resource')
  body.append('aggs', 'true')
  const signal = controller.signal
  const headers = { 'x-csrf-token': metaTags.csrfToken() }
  const args = { signal, headers, method: 'POST', body }

  const json = await (await HTTP.fetch(Routes.search_path(), args)).json()
  controller = null
  const results = json.hits.map((result) => ({
    id: result.id,
    resultType: result.type,
    name: result.name,
    customer: result.customer_name,
    url: result.type === 'Hyperlink' ? Routes.resource_path(result.id) : result.url,
    thumbnail: result.thumbnail_url,
    logo: result.logo,
    premium: result.premium,
    state: result.state,
    customerVisibleStandardCodes: result.customer_visible_standard_codes || [],
    cardCount: result.card_count,
  }))
  return { results, resultCount: json.total, nextPage: json.next, aggregates: json.aggs }
}

const redirectedToLzCode = async (query) => {
  if (!query.match(CODE_REGEX) || query.length !== lzCode.code.length) return false
  const data = await (await HTTP.fetch(Routes.search_path(), { query: { lz_code: query } })).json()
  if (!data.path || data.kind === 'text') return false

  location.assign(data.path)
  return true
}

const getStandards = async (query) => {
  const standards = await (await HTTP.fetch(Routes.standards_path(), { query: { q: query } })).json()
  return standards.map((std) => ({ key: std.uuid, title: std.displayCode, description: std.name }))
}

const getStandardLabel = (uuid) =>
  HTTP.fetch(Routes.standards_path(), { query: { uuid } })
    .then((response) => response.json())
    .then((json) => json.displayCode)

export { getResults, redirectedToLzCode, getStandards, getStandardLabel }
