<template>
  <span>
    <Popover
      :tag="tag"
      :portal-name="portalName"
      :reference-tag="referenceTag"
      :visible="showTooltip"
      :placement="placement"
      :strategy="strategy"
      :padding="popoverPadding"
      :popover-class="popoverClasses"
      :target="target"
      :target-class="cursorClass"
      enter-class="opacity-0"
      enter-to-class="opacity-100"
      leave-class="opacity-100"
      leave-to-class="opacity-0"
      :transition-duration="333"
      :class="popoverWrapperClasses"
      @mouseenter="setVisibility(true, disableHover)"
      @mouseleave="setVisibility(false, disableHover)"
      @focus="setVisibility(true, disableFocus)"
      @blur="setVisibility(false, disableFocus)"
      @content-mouseenter="setVisibility(true, disableHover)"
      @content-mouseleave="setVisibility(false, disableHover)"
    >
      <slot />

      <template #popover>
        <div :class="textClass">
          <slot name="content">
            {{ title }}
          </slot>
        </div>
      </template>
    </Popover>

    <span class="sr-only">
      <slot name="content">
        {{ title }}
      </slot>
    </span>
  </span>
</template>

<script>
import { ref, computed } from 'vue'
import Popover, { DEFAULT_PORTAL, STRATEGIES, POSITIONS } from './Popover'

export { STRATEGIES, POSITIONS, DEFAULT_PORTAL }

const SIZES = { xs: 'text-xs', sm: 'text-sm', base: 'text-base', lg: 'text-lg' }

export default {
  name: 'LzTooltip',
  components: { Popover },
  props: {
    disableHover: {
      type: Boolean,
      default: false,
    },
    disableFocus: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'div',
    },
    title: {
      type: String,
      default: '',
    },
    tooltipClasses: {
      type: String,
      default: '',
    },
    placement: {
      type: String,
      default: 'top',
    },
    size: {
      type: String,
      default: Object.keys(SIZES)[1],
      validator: (val) => Object.keys(SIZES).includes(val),
    },
    target: {
      type: String,
      default: '',
    },
    referenceTag: {
      type: String,
      default: 'span',
    },
    strategy: {
      type: String,
      default: 'absolute',
      validator: (val) => STRATEGIES.includes(val),
    },
    maxWidthClass: {
      type: String,
      default: 'max-w-xs',
    },
    useMaxWidth: {
      type: Boolean,
      default: false,
    },
    cursorClass: {
      type: String,
      default: 'cursor-help',
    },
    portalName: {
      type: String,
      default: DEFAULT_PORTAL,
    },
    popoverWrapperClasses: {
      type: String,
      default: '',
    },
  },
  setup(props) {
    const showTooltip = ref(false)
    const popoverPadding = computed(() => (props.size === 'xs' ? 'sm' : 'lg'))
    const popoverClasses = computed(() =>
      [
        props.maxWidthClass,
        props.useMaxWidth ? 'w-full' : 'w-auto',
        'transition-opacity',
        'duration-300',
        'ease-in-out',
        props.tooltipClasses,
      ].join(' '),
    )
    const textClass = computed(() => SIZES[props.size])

    const setVisibility = (val, isDisabled) => {
      if (isDisabled) return
      showTooltip.value = val
    }

    return { showTooltip, popoverPadding, popoverClasses, textClass, setVisibility }
  },
}
</script>
