<template>
  <div data-test="search-input" class="bg-base relative flex flex-grow justify-items-end rounded-full">
    <component
      :is="clickable ? 'button' : 'span'"
      :class="{ 'cursor-pointer': clickable }"
      class="absolute flex h-full w-7 items-center p-0 pl-2"
      :aria-label="`${label} button`"
      data-test="search-button"
      @click.prevent="$emit('click', $event)"
    >
      <InlineSvg
        path="icons/search"
        aria-hidden="true"
        class="text-important hover:text-important-hover h-6 w-6 flex-shrink-0"
      />
    </component>
    <input
      ref="inputRef"
      type="search"
      :placeholder="placeholder"
      :value="modelValue"
      :aria-label="`${label} input`"
      class="ml-9 inline-block rounded-r-full border-none bg-transparent pl-0 text-base"
      style="box-shadow: none"
      @focus="$emit('focus', $event)"
      @blur="$emit('blur', $event)"
      @input="$emit('update:modelValue', $event.target.value)"
      @keydown="$emit('keydown', $event)"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue'
import { InlineSvg } from 'vue_features/shared/components/ui'

export default {
  name: 'SearchInput',
  components: { InlineSvg },
  props: {
    label: { type: String, default: '' },
    placeholder: { type: String, default: '' },
    modelValue: { type: String, default: '' },
  },
  setup(_, { attrs }) {
    const inputRef = ref()
    return {
      inputRef,
      clickable: computed(() => attrs.onClick !== undefined),
      focus: () => inputRef.value.focus(),
      blur: () => inputRef.value.blur(),
    }
  },
}
</script>

<style scoped>
input[type='search']::-webkit-search-decoration,
input[type='search']::-webkit-search-cancel-button,
input[type='search']::-webkit-search-results-button,
input[type='search']::-webkit-search-results-decoration {
  -webkit-appearance: none;
}
</style>
