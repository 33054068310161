export function calculateRangeOnPage(array, { currentPage, totalPages, totalCount }) {
  if (array.length === 0) return '0'

  const onLastPage = totalPages === currentPage && totalPages !== 1,
    pageSize = array.length,
    firstShowing = onLastPage ? totalCount - array.length + 1 : (currentPage - 1) * pageSize + 1,
    lastShowing = firstShowing + array.length - 1

  return `${firstShowing.toLocaleString()}-${lastShowing.toLocaleString()}`
}
