<template>
  <div
    v-if="!isDismissed"
    :class="[
      'flex items-start space-x-4 overflow-hidden rounded-2xl border-2 border-solid',
      alertShell,
      sizeInfo.btmMargin,
    ]"
    data-test="alert"
  >
    <InlineSvg :path="stateInfo.path" :class="['h-6 w-6 flex-shrink-0', sizeInfo.iconMargin]" />
    <div :class="['flex flex-grow items-start justify-between', stateInfo.foreground]">
      <div :class="['flex-grow px-3', sizeInfo.yPadding]">
        <slot>
          <p :class="['mb-0 space-x-1 text-sm leading-6', stateInfo.textColor]" data-test="error-content">
            <span class="font-bold" data-test="alert-title">{{ title }}</span>
            <span v-if="!Array.isArray(description)">{{ description }}</span>
          </p>
          <ul v-if="Array.isArray(description)" class="ml-6 list-disc">
            <li v-for="item in description" :key="item" :class="['text-sm', stateInfo.textColor]">
              {{ item }}
            </li>
          </ul>
        </slot>
      </div>
      <button
        v-if="dismissible"
        data-test="alert-close-button"
        class="cursor-pointer p-2 text-current"
        :class="sizeInfo.btnHeight"
        :aria-label="t('dismiss')"
        @click="handleDismiss"
      >
        <span class="sr-only">{{ t('common.close') }}</span>
        <InlineSvg path="icons/close" :class="['h-6 w-6', stateInfo.textColor]" />
      </button>
    </div>
  </div>
</template>

<script>
import InlineSvg from './InlineSvg'
import { ref, computed, watch, toRef } from 'vue'
import { components, common } from 'vue_features/shared/i18n'
import { pickLocaleNamespace, namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import merge from 'lodash/merge'
import { useI18n } from 'vue-i18n'

const messages = merge(
  {},
  namespaceLocaleObj(common, 'common', { only: 'close' }),
  pickLocaleNamespace(components, 'failure_modal.dismiss'),
)
export const STATES = {
  default: {
    alertBackground: 'bg-blue-200',
    alertBorder: 'border-blue-200',
    foreground: 'bg-blue-50',
    textColor: 'text-blue-800',
    path: 'icons/info',
  },
  alt: {
    alertBackground: 'bg-neutral-400',
    alertBorder: 'border-neutral-500',
    foreground: 'bg-neutral-200',
    textColor: 'text-base',
    path: 'icons/info',
  },
  error: {
    alertBackground: 'bg-red-200',
    alertBorder: 'border-red-200',
    foreground: 'bg-red-50',
    textColor: 'text-red-800',
    path: 'icons/exclamation',
  },
  notice: {
    alertBackground: 'bg-orange-200',
    alertBorder: 'border-orange-200',
    foreground: 'bg-orange-50',
    textColor: 'text-orange-800',
    path: 'icons/notice',
  },
  success: {
    alertBackground: ['bg-green-200'],
    alertBorder: 'border-green-200',
    foreground: 'bg-green-50',
    textColor: 'text-green-800',
    path: 'icons/check',
  },
}

const SIZES = {
  sm: { btnHeight: 'h-6', btmMargin: '', yPadding: 'py-2', iconMargin: 'mt-2 ml-2' },
  md: { btnHeight: 'h-10 mt-2', btmMargin: 'mb-0', yPadding: 'py-4', iconMargin: 'mt-4 ml-4' },
}
export default {
  name: 'Alert',
  components: { InlineSvg },
  props: {
    type: {
      type: String,
      default: 'default',
      validator: (value) => Object.keys(STATES).includes(value),
    },
    size: { type: String, default: 'md', validator: (value) => Object.keys(SIZES).includes(value) },
    title: {
      type: String,
      default: '',
    },
    description: {
      type: [Array, String],
      default: '',
    },
    dismissible: {
      type: Boolean,
      default: false,
    },
  },

  setup(props, { emit }) {
    const isDismissed = ref(false)
    const stateInfo = computed(() => STATES[props.type])
    const sizeInfo = computed(() => SIZES[props.size])
    const alertShell = computed(
      () => `${stateInfo.value.alertBackground} ${stateInfo.value.alertBorder} ${stateInfo.value.textColor}`,
    )
    function handleDismiss() {
      isDismissed.value = true
      emit('dismissed')
    }
    watch(toRef(props.description), () => (isDismissed.value = false))
    const { t } = useI18n({ messages })
    return { handleDismiss, isDismissed, stateInfo, sizeInfo, alertShell, t }
  },
}
</script>
