<template>
  <div ref="topSticky" class="sticky z-10">
    <slot />
  </div>
</template>

<script>
import { defineStore } from 'vue_features/shared/composables/store_helpers'
import { ref, reactive, onUnmounted, onMounted } from 'vue'
import { useNamespacedEventListener } from 'vue_features/shared/composables'
import remove from 'lodash/remove'

const useGlobalTopSticky = defineStore('globalTopSticky', () => {
  const state = reactive({
    topStickies: [],
  })

  function addSticky(stickyElement) {
    state.topStickies.push(stickyElement)
    updateStyles()
  }

  function removeSticky(stickyElement) {
    remove(state.topStickies, stickyElement)
  }

  const updateStyles = () => {
    let top = 0
    state.topStickies.forEach((sticky) => {
      sticky.value.style.top = top
      top += window.getComputedStyle(sticky.value).height
    })
  }

  const { on: winOn } = useNamespacedEventListener(window)
  const RESIZE = 'resize.top-sticky'

  winOn(RESIZE, updateStyles)

  return { state, addSticky, removeSticky }
})

export default {
  name: 'TopSticky',
  setup() {
    const topSticky = ref(null)
    onMounted(() => useGlobalTopSticky().addSticky(topSticky))
    onUnmounted(() => useGlobalTopSticky().removeSticky(topSticky))
    return { topSticky }
  },
}
</script>
