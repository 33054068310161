import assign from 'lodash/assign'
import get from 'lodash/get'
import mapKeys from 'lodash/mapKeys'

import titleize from 'lodash_extensions/titleize'
import { AnalyticsBrowser } from '@segment/analytics-next'
let segmentAnalytics

import * as mixpanelAnalytics from './mixpanel'
import gtag from './gtag'
import pendo from './pendo'
import { location, deepKeyTransform, filter } from 'utils'

import debug from 'debug'
const log = debug('app:clients/analytics')

const LzAnalytics = {
  identify(userId, traits) {
    if (LzAnalytics.testMode) return

    if (!LzAnalytics.current_customer_traits?.customer) return

    traits = LzAnalytics.appendCommonProperties(traits)

    mixpanelAnalytics.clearCookie()
    mixpanelAnalytics.identify(userId, traits)

    const options = LzAnalytics.appendCommonOptions({ integrations: { Mixpanel: false } })
    segmentAnalytics.identify(userId, traits, options)
  },

  increment(trait, count) {
    if (LzAnalytics.current_customer_traits?.customer && !LzAnalytics.testMode && LzAnalytics.current_user_id) {
      mixpanelAnalytics.increment(trait, count)
    }
  },

  initialize(settings = {}) {
    assign(LzAnalytics, {
      /*
      NOTE: The `testMode` property short circuits calls to live analytics reporting methods during CI runs.
      If you need to ensure that a particular tracking method was called in a test case, you must stub
      the method you're spying on with an `.and.callFake()` dummy implementation and temporarily set
      `LzAnalytics.testMode = false` like this:

      ```js
      describe('tracking something', () => {
        beforeEach(() => {
          LzAnalytics.testMode = false
          spyOn(segmentAnalytics, 'track').and.callFake(() => true)
        })

        afterEach(() => {
          LzAnalytics.testMode = true
        })

        it('records the event', () => {
          LzAnalytics.track('Some event', { prop: 'value' })
          expect(segmentAnalytics.track).toHaveBeenCalled()
        })
      })
      ```

      Your test will fail otherwise, since `segmentAnalytics.track()` will not be called when `LzAnalytics.testMode`
      is truthy.
      */
      testMode:
        process.env.NODE_ENV === 'test' || process.env.RAILS_ENV === 'test' || process.env.SYSTEM_SPECS === 'true',
      ...settings,
      logged_in: settings.current_user_id !== null,
    })

    log('Initializing LzAnalytics')
    log('process.env.NODE_ENV = %s', process.env.NODE_ENV)
    log('process.env.RAILS_ENV = %s', process.env.RAILS_ENV)
    log('process.env.SYSTEM_SPECS = %s', process.env.SYSTEM_SPECS)
    log('LzAnalytics.testMode = %s', LzAnalytics.testMode)

    const providers = {
      All: false,
    }

    if (LzAnalytics.current_customer_traits?.customer) {
      providers['Mixpanel'] = {
        token: LzAnalytics.mixpanel_token,
        people: true,
      }
    }

    segmentAnalytics = AnalyticsBrowser.load({ writeKey: LzAnalytics.segment_io_token }, { integrations: providers })
    segmentAnalytics.debug(LzAnalytics.debug)

    const userTraits = {
      authType: LzAnalytics.auth_type,
      impersonatingUser: LzAnalytics.impersonating_user,
      userId: LzAnalytics.current_user_id,
      ...LzAnalytics.current_user_traits,
      ...LzAnalytics.current_customer_traits,
    }

    const gtagPromise = gtag.load(LzAnalytics.google_measurement_id, userTraits)
    const segmentPromise = new Promise((resolve) => segmentAnalytics.ready(resolve))
    const pendoPromise = loadPendo()

    return Promise.all([segmentPromise, gtagPromise, pendoPromise])
  },

  appendCommonProperties(properties = {}) {
    const commonProperties = {
      'Auth type': LzAnalytics.auth_type,
      'impersonator id': LzAnalytics.impersonator_id,
      'session read only': LzAnalytics.session_read_only,
      // Different systems have used different things for the user ID so there are three of them: $id, id, and $user_id
      // TODO: Coordinate with analytics people and unify on one user id property
      id: LzAnalytics.current_user_id,
      $id: LzAnalytics.current_user_id,
      $user_id: LzAnalytics.current_user_id,
      user_agent: navigator.userAgent,
      ...LzAnalytics.current_user_traits,
      ...LzAnalytics.current_customer_traits,
      ...properties,
    }

    // intentional loose equality check to remove null and undefined values, preserve false and 0
    return filter(commonProperties, (val) => val != null)
  },

  appendCommonOptions(options = {}, properties = {}) {
    const commonOptions = {}

    if (get(properties, 'providers')) {
      commonOptions.integrations = mapKeys(properties.providers, (_val, key) => titleize(key))
    }

    return { ...commonOptions, ...options }
  },

  appendPageProperties(properties = {}) {
    const pageProperties = {}

    if (!properties.url) {
      pageProperties.url = location.current().href
    }

    if (!properties.path) {
      pageProperties.path = location.current().pathname + location.current().search
    }

    return { ...pageProperties, ...properties }
  },

  page(...args) {
    if (LzAnalytics.testMode) return

    args[2] = LzAnalytics.appendPageProperties(LzAnalytics.appendCommonProperties(args[2]))
    args[3] = LzAnalytics.appendCommonOptions(args[3], args[2])

    segmentAnalytics.page.apply(this, args)
  },

  track(event, eventProperties, options) {
    const properties = LzAnalytics.appendCommonProperties(eventProperties)
    options = LzAnalytics.appendCommonOptions(options, eventProperties)
    const { sendBeacon } = options || {}
    delete options.sendBeacon

    return new Promise((resolve) => {
      if (LzAnalytics.testMode) return resolve()

      segmentAnalytics.ready(() => {
        log('track', event, properties, options)
        const sendEvent = () => {
          segmentAnalytics.track(event, this.formatProperties(properties), options, resolve)
          gtag.track(event, properties)
          pendo.track(event, eventProperties)
        }
        if (!sendBeacon) return sendEvent()
        mixpanelAnalytics.withTempConfig({ api_transport: 'sendBeacon' }, sendEvent)
      })
    })
  },

  clear_residual_traits() {
    if (LzAnalytics.logged_in) return

    segmentAnalytics.ready(() => {
      if (mixpanelAnalytics.cookieProperties().$name) {
        mixpanelAnalytics.clearCookie()
      }
    })
  },

  formatProperties(properties) {
    return deepKeyTransform(properties, (key) => key.toLowerCase())
  },
}

function loadPendo() {
  if (LzAnalytics.current_user_traits && LzAnalytics.current_user_traits['Account type'] === 'user') {
    return pendo.load(
      LzAnalytics.pendo_api_key,
      { userId: LzAnalytics.current_user_id, ...LzAnalytics.current_user_traits },
      LzAnalytics.current_customer_traits,
    )
  }

  return Promise.resolve()
}

export default LzAnalytics
