import { setupDevtoolsPlugin } from '@vue/devtools-api'
import { getStore, storeTracker } from '../vue_features/shared/composables/store_helpers'
import { isRef, watch } from 'vue'

const inspectorId = 'com.ilclassroom.datastore-inspector'
const timelineId = 'com.ilclassroom.datastore-timeline'
export function setupDevtools(app) {
  setupDevtoolsPlugin(
    {
      id: 'com.ilclassroom',
      label: 'Imagine Learning Classroom',
      app,
    },
    (api) => {
      // Inspector panel to browse the data store
      api.addInspector({
        id: inspectorId,
        label: 'ILC Data Store',
        icon: 'storage',
      })

      // List the possible datastores in the inspector
      api.on.getInspectorTree((payload, context) => {
        if (payload.inspectorId === inspectorId) {
          payload.rootNodes = [
            {
              id: 'data-stores-root',
              label: 'Data Stores',
              children: storeTracker.value.sort().map((storeId) => {
                return { id: storeId, label: storeId }
              }),
            },
          ]
        }
      })

      // View the data in the store
      api.on.getInspectorState((payload, context) => {
        if (payload.inspectorId === inspectorId) {
          const storeId = payload.nodeId

          // If it is the root, show state from all stores
          if (storeId === 'data-stores-root') {
            payload.state = Object.fromEntries(
              storeTracker.value.sort().map((storeId) => {
                const store = getStore(storeId)
                return [storeId, getStoreData(store)]
              }),
            )
          } else {
            const store = getStore(storeId)
            payload.state = { state: getStoreData(store) }
          }
        }
      })

      // Handle editing a value in the store
      api.on.editInspectorState((payload) => {
        if (payload.inspectorId === inspectorId) {
          const storeId = payload.nodeId === 'data-stores-root' ? payload.type : payload.nodeId
          const store = getStore(storeId)
          payload.set(store)
          api.sendInspectorState(inspectorId)
        }
      })

      // Add a timeline to view when changes are made to values in the datastore
      api.addTimelineLayer({
        id: timelineId,
        label: 'ILC Data Store',
        color: 0x00007c,
      })

      const storeWatchers = []

      // Watch the store tracker to see when a store is added or removed
      watch(
        storeTracker,
        (newValue, oldValue) => {
          storeWatchers.forEach((watcher) => watcher())

          api.addTimelineEvent({
            layerId: timelineId,
            event: {
              time: api.now(),
              data: {
                newValue,
                oldValue,
              },
              title: 'Store Added/Removed',
            },
          })

          api.sendInspectorTree(inspectorId)

          // Watch each individual store for changes
          storeTracker.value.map((storeId) => {
            const store = getStore(storeId)
            storeWatchers.push(
              watch(store.state, (newValue, oldValue) => {
                api.sendInspectorState(inspectorId)
                api.addTimelineEvent({
                  layerId: timelineId,
                  event: {
                    time: api.now(),
                    data: {
                      newValue,
                      oldValue,
                    },
                    title: storeId + ' Updated',
                  },
                })
              }),
            )
          })
        },
        { immediate: true },
      )
    },
  )
}

function getStoreData(store) {
  const values = []

  Object.keys(store)
    .sort()
    .forEach((key) => {
      if (key === 'state') return
      const value = store[key]

      try {
        if (typeof value !== 'function') {
          // Only state values are editable. Computed values aren't
          values.push({ key: key, value: getValue(value), editable: key in store.state })
        }
      } catch (e) {
        values.push([key, 'Error: ' + e.message])
      }
    })

  return values
}

function getValue(value) {
  if (isRef(value) && typeof value.value !== 'function') {
    return value.value
  }

  return value
}
