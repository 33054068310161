<template>
  <slot v-bind="$attrs" />
</template>

<script>
/* eslint-disable vue/component-api-style */
/*
  In specs, any component that uses this Poller must destroy the mounted component to avoid polling after the test finishes:
  afterEach(function () { this.wrapper.unmount() })
  Without this you may see uncaught promise errors after our test suite runs.
*/
export default {
  props: {
    active: {
      type: Boolean,
      default: true,
    },
    interval: {
      type: Number,
      default: 5000,
    },
  },

  data() {
    return {
      timerId: null,
    }
  },

  watch: {
    active: {
      immediate: true,
      handler(active) {
        if (active) {
          this.start()
        } else {
          this.stop()
        }
      },
    },
  },

  beforeUnmount() {
    this.stop()
  },

  methods: {
    start() {
      this.timerId = setTimeout(() => {
        const done = () => {
          if (this.active) this.start()
        }
        this.$emit('tick', done)
      }, this.interval)
    },
    stop() {
      clearTimeout(this.timerId)
      this.timerId = null
    },
  },
}
</script>
