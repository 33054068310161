import { ref, onMounted, onUnmounted } from 'vue'

export const createUseTimer = (setTimer, cancelTimer) => (callback, timeout) => {
  const timer = ref(null)
  const done = ref(false)
  const cancelled = ref(false)
  const error = ref(null)
  const cancel = () => {
    cancelTimer(timer.value)
    done.value = true
    cancelled.value = true
  }
  const reset = () => {
    done.value = false
    cancelled.value = false
    error.value = null
    timer.value = null
  }

  onMounted(() => {
    reset()

    timer.value = setTimer(() => {
      try {
        callback()
      } catch (e) {
        error.value = e
      } finally {
        done.value = true
      }
    }, timeout)
  })

  onUnmounted(() => {
    cancelTimer(timer.value)
  })

  return { timer, done, cancelled, error, cancel }
}

export const useTimeout = createUseTimer(setTimeout, clearTimeout)
export const useInterval = createUseTimer(setInterval, clearInterval)
