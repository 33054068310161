import { computed, unref } from 'vue'

export function isResourceOfType(resource, type) {
  return unref(resource)?.type === type
}

export function isQuestion(resource) {
  return isResourceOfType(resource, 'LearnosityItem')
}

export function isGeogebra(resource) {
  return isResourceOfType(resource, 'GeogebraItem')
}

export function isDocument(resource) {
  return isResourceOfType(resource, 'Document')
}

export function isAudio(resource) {
  return isResourceOfType(resource, 'Audio')
}

export function isSlide(resource) {
  return isResourceOfType(resource, 'Slide')
}

export function isHyperlink(resource) {
  return isResourceOfType(resource, 'Hyperlink')
}

export function isVideo(resource) {
  return isResourceOfType(resource, 'Video')
}

export function isWiki(resource) {
  return isResourceOfType(resource, 'Wiki')
}

export function useResourceTypes(resourceRef) {
  return {
    isQuestion: computed(() => isQuestion(resourceRef)),
    isSlide: computed(() => isSlide(resourceRef)),
    isHyperlink: computed(() => isHyperlink(resourceRef)),
    isWiki: computed(() => isWiki(resourceRef)),
    isVideo: computed(() => isVideo(resourceRef)),
    isGeogebra: computed(() => isGeogebra(resourceRef)),
    isDocument: computed(() => isDocument(resourceRef)),
  }
}
