class MetaTags {
  get(name) {
    const el = document.querySelector(`meta[name=${name}]`)
    return el ? el.content : null
  }

  csrfToken() {
    return this.get('csrf-token')
  }
}

const singleton = new MetaTags()

export default singleton
