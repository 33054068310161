import $script from 'scriptjs'
import { deepKeyTransform } from 'utils'
import { logAndNotify } from 'utils/log_and_notify'

function gtag() {
  window.ga4DataLayer = window.ga4DataLayer || []
  // GA4 doesn't work with rest parameters and requires `arguments`
  // eslint-disable-next-line prefer-rest-params
  window.ga4DataLayer.push(arguments)
}

function formatProperties(properties) {
  // GA4 requires "The parameter name must consist of letters, numbers, or underscores, and it must start with a letter."
  return deepKeyTransform(properties, (key) => key.replaceAll(' ', '_').replaceAll(/[^a-z0-9_]/gi, ''))
}

function configure(id, traits) {
  gtag('js', new Date())
  gtag('config', id, formatProperties(traits))
}

function load(id, traits) {
  return new Promise((resolve) => {
    $script(`https://www.googletagmanager.com/gtag/js?id=${id}&l=ga4DataLayer`, function () {
      try {
        configure(id, traits)
      } catch (e) {
        logAndNotify(e)
      }
      resolve()
    })
  })
}

function track(event, properties) {
  try {
    gtag('event', event, formatProperties(properties))
  } catch (e) {
    logAndNotify(e)
  }
}

export default {
  gtag,
  load,
  track,
}
