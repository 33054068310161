<template>
  <LzModal
    v-if="canModalShow(name)"
    id="avatar-modal"
    class="lzui-modal--large"
    :modal-title="t('components.avatar_modal.title')"
    :modal-id="name"
    :can-cancel="true"
    :autoclose="false"
    @close-modal="cancelSelect"
    @bg-click="cancelSelect"
  >
    <section class="space-y-8">
      <div
        class="grid"
        :class="gradeBand === 'k5' ? 'grid-cols-2 gap-4 p-4 sm:grid-cols-3' : 'grid-cols-2 gap-2 p-2 sm:grid-cols-5'"
      >
        <button
          v-for="(avatarItem, index) in avatars"
          :key="index"
          class="relative flex flex-col items-center rounded-3xl transition duration-300"
          :class="avatarActive === index ? 'bg-green-accent' : 'cursor-pointer hover:bg-green-100'"
          @click="selectAvatar(index)"
        >
          <img
            :src="avatarItem.modal"
            :alt="avatarItem.title"
            class="h-auto w-full rounded-3xl bg-cover bg-bottom transition duration-200"
            :class="
              avatarActive === index
                ? 'scale-110 transform'
                : 'transition duration-200 group-hover:scale-105 group-hover:transform'
            "
          />
          <div
            v-if="avatarActive === index"
            class="bg-green shadow-md-100 animate-fadeInScale absolute -bottom-2 -right-2 h-12 w-12 rounded-full"
          >
            <InlineSvg path="icons/check" class="h-full w-full text-white" />
          </div>
        </button>
        <button
          class="btn btn--default relative mx-4 my-7 flex flex-col space-y-2"
          :class="!avatarActive ? 'border-green-accent border-2 bg-green-100 shadow-none' : ''"
          @click="selectAvatar(null)"
        >
          <InlineSvg path="icons/no-symbol" class="h-12 w-12" />
          <div>{{ t('components.avatar_modal.no_avatar') }}</div>
          <div
            v-if="!avatarActive"
            class="bg-green shadow-md-100 animate-fadeInScale absolute -bottom-2 -right-2 h-12 w-12 rounded-full"
          >
            <InlineSvg path="icons/check" class="h-full w-full text-white" />
          </div>
        </button>
      </div>
      <footer class="flex flex-row justify-center space-x-4 py-2">
        <button class="btn" @click.stop="cancelSelect">{{ t('components.avatar_modal.cancel') }}</button>
        <button class="btn btn--primary" @click.stop="saveAvatar">{{ t('components.avatar_modal.save') }}</button>
      </footer>
    </section>
  </LzModal>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import InlineSvg from 'vue_features/shared/components/ui/InlineSvg'
import { LzModal } from 'vue_features/shared/components/ui'
import { closeModal, canModalShow } from 'vue_features/shared/composables/use_global_modals'
import { useCurrentUserStore } from 'vue_features/shared/store/composables'
import { components } from 'vue_features/shared/i18n'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { useI18n } from 'vue-i18n'
import merge from 'lodash/merge'
import { LzAnalytics } from 'clients'

const name = 'AvatarModal'

const messages = merge({}, namespaceLocaleObj(components, 'components', { only: ['avatar_modal'] }))
const { t } = useI18n({ messages })
const { avatars, avatar, setAvatar, gradeBand } = useCurrentUserStore()
const close = () => closeModal(name)
const avatarActive = ref(null)
onMounted(() => {
  avatarActive.value = avatar.value
})

const saveAvatar = () => {
  setAvatar(avatarActive.value)
  LzAnalytics.track('Avatar Chosen', { source: 'modal', action: 'avatar chosen', value: avatarActive.value })
  close()
}
const selectAvatar = (index) => {
  avatarActive.value = index
}
const cancelSelect = () => {
  avatarActive.value = avatar.value
  LzAnalytics.track('Avatar Modal', { source: 'modal', action: 'close' })
  close()
}
</script>
