import { onUnmounted, watch } from 'vue'

export default function useMutationObserver(elementRef, callback, options = {}) {
  let observer

  function cleanup() {
    if (observer) {
      observer.disconnect()
      observer = undefined
    }
  }

  watch(
    elementRef,
    (newVal, oldVal) => {
      if (oldVal) cleanup()
      if (newVal) {
        observer = new MutationObserver(callback)
        observer.observe(newVal, options)
      }
    },
    {
      immediate: true,
    },
  )

  onUnmounted(cleanup)
}
