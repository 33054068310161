<template>
  <div
    v-if="canDismissibleShow(prefixedId)"
    class="relative flex items-center overflow-hidden bg-cover"
    :style="{ backgroundImage: `url('${backgroundImage}')` }"
  >
    <div class="from-primary-700 via-primary-700/25 flex-1 bg-gradient-to-r p-6 text-white">
      <div
        class="m-auto flex w-full max-w-7xl flex-row items-center px-4"
        :class="[
          textAlign === 'center' ? 'justify-center text-center' : 'justify-start text-left',
          foregroundImageAlign === 'col' ? 'flex-col' : 'flex-row',
        ]"
      >
        <div>
          <h3 class="text-inverted m-0">{{ heading }}</h3>
          <p class="text-inverted mt-2">{{ body }}</p>
          <LzButton v-if="ctaUrl && ctaLabel" tag="a" target="_blank" :href="ctaUrl" class="mt-4">
            {{ ctaLabel }}
          </LzButton>
        </div>
        <InlineSvg
          v-if="foregroundImagePath"
          :path="foregroundImagePath"
          :class="[`text-white, max-h-40 flex-grow ${foregroundImageClasses} `]"
        />
      </div>
    </div>
    <button
      class="absolute right-0 top-0 cursor-pointer p-2"
      :aria-label="$t('common.close')"
      @click="closeDismissible(prefixedId)"
    >
      <InlineSvg path="icons/x-circle" class="w-6 text-white" />
    </button>
  </div>
</template>

<script>
import { computed } from 'vue'
import LzButton from './LzButton'
import InlineSvg from './InlineSvg'
import { useDismissibles } from 'vue_features/shared/composables'

export default {
  name: 'AnnouncementBanner',
  components: {
    LzButton,
    InlineSvg,
  },
  props: {
    id: {
      type: String,
      required: true,
    },
    showCountLimit: {
      type: Number,
      default: null,
    },
    backgroundImage: {
      type: String,
      required: true,
    },
    foregroundImagePath: {
      type: String,
      default: null,
    },
    foregroundImageAlign: {
      type: String,
      default: null,
    },
    foregroundImageClasses: {
      type: String,
      default: null,
    },
    heading: {
      type: String,
      required: true,
    },
    body: {
      type: String,
      required: true,
    },
    textAlign: {
      type: String,
      required: false,
      default: null,
    },
    ctaUrl: {
      type: String,
      required: false,
      default: null,
    },
    ctaLabel: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const prefixedId = computed(() => `announcement-banner-${props.id}`)
    const { showDismissible, closeDismissible, canDismissibleShow } = useDismissibles()
    showDismissible(prefixedId.value, { limit: props.showCountLimit })
    return { prefixedId, closeDismissible, canDismissibleShow }
  },
}
</script>
