<template>
  <component :is="tag" :class="['grid', defaultClasses]">
    <slot />
  </component>
</template>

<script>
import { computed } from 'vue'

export const DEFAULTS = {
  'max-w': 'max-w-7xl',
  'grid-cols': 'grid-cols-12',
  px: 'px-3',
  'gap-x': 'gap-x-3',
  'md:px': 'md:px-6',
  'md:gap-x': 'md:gap-x-6',
}

export default {
  name: 'Grid',
  props: {
    tag: { type: String, default: 'div' },
    disableDefault: { type: [String, Array], default: () => [] },
  },
  setup(props) {
    const defaultClasses = computed(() => {
      return Object.entries(DEFAULTS).map(([key, value]) => {
        return props.disableDefault.includes(key) ? '' : value
      })
    })

    return { defaultClasses }
  },
}
</script>
