import { createApp, h } from 'vue'
import { createI18n } from 'vue-i18n'
import { useGlobalStore } from 'vue_features/shared/store/composables'
import applyPlugins from 'initializers/vue_plugins'
import { monitorApp } from 'clients/sentry'
import { createLzRouter } from 'vue_features/shared/router'
import { location as $location } from 'utils'
import { setupDevtools } from 'clients/devtools'

export function createVueApp(options, addMonitoring = true) {
  const app = applyPlugins(createApp(options))
  if (addMonitoring) {
    monitorApp(app)
  }
  return app
}

export function buildVueApp({ component, messages, options, router, locale, props, attrs }, addMonitoring = true) {
  const i18n = createI18n({
    messages,
    locale: locale || useGlobalStore().state.locale,
    legacy: false,
    globalInjection: true,
  })

  const app = createVueApp(
    {
      ...options,
      render: () => h(component, { ...props, ...attrs }),
    },
    addMonitoring,
  )

  if (process.env.NODE_ENV === 'development' || __VUE_PROD_DEVTOOLS__) {
    setupDevtools(app)
  }

  if (router) {
    app.use(router)
  } else {
    app.use(createLzRouter({ routes: [{ path: $location.pathname(), component }] }))
  }
  app.use(i18n)

  return app
}
