<template>
  <div>
    <div class="flex items-center space-x-2">
      <button ref="dropdownTarget" class="flex-1" :class="buttonClassForState" type="button" @click.stop="toggleOpen">
        <slot name="buttonContent" v-bind="buttonSlotProps">
          {{ buttonText || buttonPlaceholder }}
        </slot>
      </button>
    </div>
    <Popover
      tag="div"
      :placement="dropdownPlacement"
      :visible="isOpen"
      :portal-name="portalName"
      :target="dropdownTarget"
      :arrow-size="0"
      :popover-class="dropdownClass"
      @close="close"
    >
      <template #popover>
        <div ref="dropdownContent">
          <slot v-bind="{ close }" />
        </div>
      </template>
    </Popover>
  </div>
</template>

<script>
import { computed, ref, watch } from 'vue'
import Popover from './Popover'
import debug from 'debug'

const log = debug('app:vue_features/shared/components/ui/filter_dropdown')

export default {
  name: 'DropdownPanel',
  components: { Popover },
  props: {
    buttonText: {
      type: String,
      default: '',
    },
    buttonPlaceholder: {
      type: String,
      default: '',
    },
    buttonClass: {
      type: [String, Array, Object],
      default: () => [],
    },
    buttonActiveClass: {
      type: [String, Array, Object],
      default: () => [],
    },
    dropdownClass: {
      type: [String, Array, Object],
      default: () => ['w-full', 'max-w-md', 'lg:max-w-2xl'],
    },
    portalName: {
      type: String,
      default: 'root',
    },
    closeOnOutsideClick: {
      type: Boolean,
      default: true,
    },
    closeOnEscape: {
      type: Boolean,
      default: true,
    },
    dropdownPlacement: {
      type: String,
      default: 'bottom',
    },
  },
  setup(props, { emit }) {
    const dropdownContent = ref(null)
    const dropdownTarget = ref(null)
    const isOpen = ref(false)
    const buttonClassForState = computed(() => [props.buttonClass, isOpen.value && props.buttonActiveClass].flat())
    const buttonSlotProps = computed(() => ({
      buttonPlaceholder: props.buttonPlaceholder,
      buttonText: props.buttonText,
      isOpen: isOpen.value,
    }))
    const toggleOpen = () => {
      isOpen.value = !isOpen.value
      emit('toggle', isOpen.value)
    }
    const close = () => {
      if (!isOpen.value) return

      log('close dropdown')
      isOpen.value = false
      emit('toggle', isOpen.value)
    }
    const closeOnOutsideClick = (event) => {
      const isOutsideClick = !event.composedPath().find((el) => el === dropdownContent.value)

      if (!props.closeOnOutsideClick || !isOutsideClick) return

      log('Received click outside open dropdown: %O', event)
      close()
    }
    const handleEsc = (event) => {
      if (!props.closeOnEscape || event.key !== 'Escape' || !isOpen.value) return
      close()
    }

    watch(
      isOpen,
      (currentlyOpen) => {
        if (currentlyOpen) {
          log('Dropdown open. Listening for outside clicks')
          document.addEventListener('click', closeOnOutsideClick, false)
          document.addEventListener('keyup', handleEsc, false)
        } else {
          log('Dropdown closed. Removing click listener.')
          document.removeEventListener('click', closeOnOutsideClick)
          document.removeEventListener('keyup', handleEsc)
        }
      },
      { immediate: true },
    )

    return { dropdownTarget, dropdownContent, isOpen, buttonSlotProps, toggleOpen, buttonClassForState, close }
  },
}
</script>
