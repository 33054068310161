import { LzAnalytics } from 'clients'

// Adds click tracking to a Vue component
// Replaces ko binding handler track_link.js
//
// Usage:
// <a v-track-click="{eventName, eventProperties}">
// <a v-track-click.once="{eventName, eventProperties, isDisabled}">
// <a v-track-click.sendBeacon="{eventName, eventProperties}">
// etc.

function beforeMount(el, { value, modifiers: { once = false, sendBeacon = false } }) {
  const { isDisabled = false } = value
  const { eventName, eventProperties = {} } = value

  if (isDisabled || !eventName) return

  el.addEventListener(
    'click',
    () => {
      LzAnalytics.track(eventName, eventProperties, { sendBeacon })
    },
    { once },
  )
}

export default {
  beforeMount,
}
