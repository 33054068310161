/**
 “String” Method

 _.titleize([string=''])

 Converts string to Title Case.  Named after rails #titleize

 Arguments

 [string=''] (string): The string to convert.
 Returns

 (string): Returns the Title Case string.

 Example

 _.titleize('FooBar');
 // => 'Foo Bar'

 _.titleize('--foo-bar--');
 // => 'Foo Bar'

 _.titleize('foo_bar');
 // => 'Foo Bar'
 */

import flow from 'lodash/flow'
import trim from 'lodash/trim'
import classify from './classify'

const spaceCapitals = (string) => string.replace(/([A-Z])/g, ' $1')

export default (string) => flow([classify, spaceCapitals, trim])(string)
