<template>
  <LzAccordion
    :open="!isSlotUsed($slots, 'header') || (isSlotUsed($slots, 'default') && !collapsible)"
    @toggled.once="$emit('open')"
  >
    <template #head="{ toggle, isOpen }">
      <MultiClickable
        v-if="isSlotUsed($slots, 'header') || isSlotUsed($slots, 'actions')"
        class="rounded-t-2xl"
        :class="[{ 'border-base border-b': isOpen && headerBorder, 'rounded-b-2xl': !isOpen }, headerClasses]"
        :href="targetHref"
        :size="actionSize"
        :target-classes="[
          'flex items-start group rounded-t-2xl py-4',
          { 'cursor-default': !collapsible, 'rounded-b-2xl': !isOpen },
          headerTargetClasses,
        ]"
        :action-classes="['flex flex-wrap', headerActionClasses]"
        @main-click="collapsible && toggle()"
      >
        <AccordionIcon v-if="collapsible" data-test="card-open-handle" size="md" :is-open="isOpen" class="flex-none" />
        <label
          :for="`card-checkmark-${inputId}`"
          :class="['z-20 m-0 flex items-start gap-x-2', { 'flex-grow': !collapsible }]"
          @click.stop
        >
          <Checkbox
            v-if="selectable"
            :id="`card-checkmark-${inputId}`"
            data-test="card-select"
            :checked="selectChecked"
            :disabled="selectDisabled"
            :indeterminate="selectIndeterminate"
            class="mt-1 flex-none"
            @change="$emit('select-all', $event)"
          />
          <slot name="header" />
        </label>
        <template #actions>
          <slot name="actions" />
        </template>
      </MultiClickable>
      <span v-else class="hidden" data-test="hidden-head" />
    </template>
    <template #body="{ isOpen }">
      <div
        v-show="isOpen"
        data-test="card-body"
        :class="['rounded-b-2xl bg-white', { 'rounded-t-2xl': !isSlotUsed($slots, 'header') }]"
      >
        <slot />
      </div>
    </template>
  </LzAccordion>
</template>

<script>
import { computed } from 'vue'
import AccordionIcon from './AccordionIcon'
import MultiClickable from './MultiClickable'
import LzAccordion from './LzAccordion'
import Checkbox from './Checkbox'
import { isSlotUsed } from 'vue_features/shared/helpers/slot_helpers'

export default {
  name: 'Card',
  components: { LzAccordion, AccordionIcon, MultiClickable, Checkbox },
  props: {
    inputId: { type: String, default: '1' },
    targetHref: { type: String, default: '' },
    collapsible: { type: Boolean, default: false },
    selectChecked: { type: Boolean, default: false },
    selectDisabled: { type: Boolean, default: false },
    selectIndeterminate: { type: Boolean, default: false },
    headerBorder: { type: Boolean, default: true },
    headerClasses: { type: [String, Array, Object], default: 'bg-focus' },
    headerTargetClasses: { type: [String, Array, Object], default: '' },
    headerActionClasses: { type: [String, Array, Object], default: '' },
    actionSize: { type: String, default: 'x-full' },
  },
  setup(_, { attrs }) {
    return { isSlotUsed, selectable: computed(() => !!attrs.onSelectAll) }
  },
}
</script>
