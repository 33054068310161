import debug from 'debug'
const log = debug('app:utils/promises')

// Returns a promise that will be delayed by the specified duration in ms
// Can also include data that you would like to pass on in the promise chain.
//
// Usage:
//   const promise = delayPromise(5000, data);
//   promise
//    .then(() => alert('Resolved', foo)) // Resolves after 5 sec
//    .then((foo) => alert(foo))          // Passes foo onto next then in chain
//
//
export function delayPromise(duration, data) {
  return new Promise((resolve) => setTimeout(() => resolve(data), duration))
}

// Returns a promise that can be resolved externally
//
// Usage:
//   const promise = controllablePromise();
//   promise.then(() => alert('Resolved'));
//   promise.resolve();
//
export function controllablePromise() {
  let res, rej

  const promise = new Promise((resolve, reject) => {
    res = resolve
    rej = reject
  })

  promise.resolve = (...args) => {
    res(...args)
    return promise
  }

  promise.reject = (...args) => {
    rej(...args)
    return promise
  }

  return promise
}

// Given a promise and a time limit, returns a new promise
// that resolves after the time limit if the given promise
// has not resolved. Note: does not cancel the given promise;
// which could still resolve separately beyond the time limit.
//
// Usage:
//   const slowPromise = mySlowPromise();
//   const timedPromise = promiseTimeout(slowPromise, 3000);
//   timedPromise.then(result => result === 'timeout'? alert('Too much time!'));
//
export function promiseTimeout(promise, ms) {
  let timer
  const clearTimeout = () => {
    if (timer) window.clearTimeout(timer)
    return promise
  }
  promise.then(clearTimeout)

  const timeoutPromise = new Promise((resolve) => {
    timer = window.setTimeout(() => {
      clearTimeout()
      resolve('timeout')
      log('timed out!')
    }, ms)
  })

  return Promise.race([promise, timeoutPromise])
}

export function pollUntil(func, ms) {
  let interval
  const dispose = () => {
    if (interval) {
      log('clearing poll interval')
      clearInterval(interval)
      interval = null
    }
  }
  const promise = new Promise((resolve) => {
    interval = setInterval(() => {
      if (func()) {
        dispose()
        resolve(true)
      }
    }, ms)
  })
  promise.dispose = dispose
  return promise
}
