<template>
  <transition
    :appear="canDismissibleShow(viewedId)"
    enter-active-class="transition-transform duration-500 ease-in delay-300 transform"
    enter-from-class="-translate-y-full opacity-0"
    enter-to-class="translate-y-0 opacity-100"
  >
    <div
      v-if="canDismissibleShow(displayId)"
      :data-test="canDismissibleShow(viewedId) ? 'page_banner_unviewed' : 'page_banner'"
      class="bg-inverted py-2 pl-4 pr-0"
    >
      <div class="mx-auto flex max-w-screen-xl">
        <div class="flex-grow">
          <p class="text-inverted mb-0 py-0.5 text-center text-xs font-semibold" data-test="banner_content">
            <slot />
            <a
              v-if="!!learnLink"
              :href="learnLink"
              class="text-accent-inverted hover:text-accent-inverted-hover"
              data-test="learn_link"
            >
              {{ t('learn_more') }}
            </a>
          </p>
        </div>
        <div class="flex-none">
          <button
            class="text-inverted cursor-pointer px-2"
            aria-label="close"
            data-test="close_button"
            @click.prevent="closeDismissible(displayId)"
          >
            <LzIcon path="icons/close" />
          </button>
        </div>
      </div>
    </div>
  </transition>
</template>

<script setup>
import { computed, onMounted } from 'vue'
import { namespaceLocaleObj } from 'vue_features/shared/helpers/i18n_helper'
import { useDismissibles } from 'vue_features/shared/composables'
import { common } from 'vue_features/shared/i18n'
import LzIcon from './LzIcon'
import { useI18n } from 'vue-i18n'

const props = defineProps({
  name: { type: String, required: true },
  learnLink: { type: String, default: '' },
})
const baseId = computed(() => props.name.replaceAll(/[^\w]/g, '_'))
const displayId = computed(() => `page-banner-${baseId.value}`)
const viewedId = computed(() => `page-banner-viewed-${baseId.value}`)
const { showDismissible, closeDismissible, canDismissibleShow } = useDismissibles()

showDismissible(displayId.value)
showDismissible(viewedId.value)

onMounted(() => {
  closeDismissible(viewedId.value)
})

const { t } = useI18n({ messages: namespaceLocaleObj(common, '', { only: 'learn_more' }) })
</script>
