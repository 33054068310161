import { computed, reactive, readonly } from 'vue'
import upperFirst from 'lodash/upperFirst'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'
import generateFilters from './generate_filters'

const RESULTS_PER_PAGE = 20
const DEFAULT_SORT = 'relevance'
const QUERY_KEY = '_query'
const UNBRACKETED_FILTERS = ['next', 'q', 'sort']

export const useSearchFiltersStore = defineStore('search_filters', () => {
  // The filter options tree is broken into three stacks: filter configs, option lists, and selected option keys.
  // Configs are only exposed as readonly. Option lists and selected state should never be manipulated directly
  // in the components. Instead, methods are provided to expose readonly versions of this data.
  const { filterConfigs, optionLists, selectedOptions, restrictingFilters, restrictedFilters, convertOption } =
    generateFilters()
  const state = reactive({
    optionLists,
    selectedOptions,
    sort: DEFAULT_SORT,
    query: '',
  })

  // Ordered presentational collection of filters and filter groups
  // FIXME: Wrap in readonly() once on Vue 3
  const filterLayout = [
    filterConfigs.content_type,
    filterConfigs.from,
    filterConfigs.collections,
    { name: 'lesson_plans', filters: [filterConfigs.subject, filterConfigs.grades, filterConfigs.standards] },
    { name: 'advanced', filters: [filterConfigs.premium, filterConfigs.state], authorOnly: true },
  ]

  // Primary option helpers
  const _getOption = (filterName, key) => state.optionLists[filterName]?.find((opt) => opt.filterKey === key)
  const _getOptionIndex = (filterName, key) => state.optionLists[filterName]?.findIndex((opt) => opt.filterKey === key)
  const addOption = (filterName, opt) => {
    // Convert option object and add
    const obj = convertOption(filterName, opt)
    if (_getOption(filterName, obj.filterKey)) return // No duplicates
    state.optionLists[filterName]?.push(obj)

    if (obj.checked) {
      // If this new option is pre-checked, select it and clear any incompatible options
      _selectOption(filterName, obj.filterKey)
      _clearRestricted(filterName, obj.filterKey)
    }
    return obj
  }
  const setOption = (filterName, key, value = true) => {
    // (Un-)set an existing option and any dependents
    const option = _getOption(filterName, key)
    if (!option) return _optionNotFound(filterName, key, value)

    // Lookup options are checked or absent - setting to false means removing
    if (!value && filterConfigs[filterName]?.lookup) return removeOption(filterName, key)

    // Set the checked display state and select option
    option.checked = !!value
    _selectOption(filterName, key, value)

    // Clear suboptions if unchecking, and handle side effects
    if (!value && option.subfilterName) _clearOptions(option.subfilterName)
    _setOptionSideEffects(filterName, key, value)
  }
  const removeOption = (filterName, key) => {
    if (key === QUERY_KEY) {
      return (state.query = '')
    }
    // Deselect an option and remove it
    _selectOption(filterName, key, false)
    const index = _getOptionIndex(filterName, key)
    if (index >= 0) state.optionLists[filterName].splice(index, 1)
  }
  const _clearOptions = (filterName, args = {}) => {
    const isLookup = !!filterConfigs[filterName]?.lookup
    state.optionLists[filterName]?.forEach((opt) => {
      const checking = opt.filterKey === args.except // Check exception, uncheck others
      if (isLookup) return checking || removeOption(filterName, opt.filterKey) // Clear lookup options by removing
      opt.checked = checking
      _selectOption(filterName, opt.filterKey, checking)
      if (!checking && opt.subfilterName) _clearOptions(opt.subfilterName)
    })
  }

  // Option selection helpers
  const _selectOption = (filterName, key, selecting = true) => {
    // If option does not need to be selected, bail out
    if (!_deselectedAndSelecting(filterName, key, selecting)) return

    // Add key to checked options in presentation order
    const selectedOpts = state.selectedOptions[filterName]
    const index = _getOptionIndex(filterName, key)
    const before = selectedOpts.findIndex((other) => _getOptionIndex(filterName, other) > index)
    before >= 0 ? selectedOpts.splice(before, 0, key) : selectedOpts.push(key) // Insert or append
  }
  const _deselectedAndSelecting = (filterName, key, selecting) => {
    // Deselect option or determine it does not need to be selected
    const pos = state.selectedOptions[filterName]?.indexOf(key)
    const selected = pos >= 0
    if (pos === undefined) return false // Options not found - nothing to do
    if (selecting) return !selected // Selecting, return whether needs to be selected
    return selected && !state.selectedOptions[filterName].splice(pos, 1) // Deselecting, remove if present (return false)
  }

  // Option interaction helpers
  const _optionNotFound = (filterName, key, checking) => {
    // If unchecking or data is already loaded just bail out
    if (!checking || filterConfigs[filterName]?.loaded) return

    // Dynamic or lookup option - leave a placeholder so replacement option gets checked
    const newopt = addOption(filterName, { filterKey: key, label: '...', checked: true })

    // If filter has a label lookup, fetch the label and apply to newly generated option
    const filter = filterConfigs[filterName]
    if (filter?.lookup?.labelApi) filter.lookup.labelApi(key).then((label) => (newopt.label = label))
  }
  const _setOptionSideEffects = (filterName, key, checking) => {
    // (Un-)check the master option if present
    const filter = filterConfigs[filterName]
    if (filter?.superfilter) _checkMasterOption(filterName, filter.superfilter, checking)

    // Clear any incompatible filter options
    _clearRestrictions(filterName, key, checking)
    if (checking) _clearRestricted(filterName, key)
  }
  const _checkMasterOption = (filterName, superfilter, checking) => {
    // Get superfilter option and ensure it's a master type
    const masterOpt = _getOption(superfilter.name, superfilter.key)
    if (!masterOpt?.master) return

    // Set visual checked state of master according to sub's checked state
    // and convert selection between master option and subs
    masterOpt.checked = !!state.optionLists[filterName]?.find((opt) => opt.checked)
    _convertMasterOption(filterName, superfilter, checking)
  }
  const _convertMasterOption = (filterName, superfilter, checking) => {
    // Get sub options and checked count
    const opts = state.optionLists[filterName]
    if (!opts) return
    const numChecked = state.selectedOptions[filterName]?.length || 0

    // See if we need to switch between selecting the master option and the sub options
    if ((checking && numChecked === 1) || (!checking && numChecked + 1 === opts.length)) {
      // Just (un-)checked the first sub - select subs instead of master
      _selectOption(superfilter.name, superfilter.key, false)
    } else if (checking && numChecked === opts.length) {
      // Just checked the last option - select master instead of subs
      opts.forEach((opt) => _selectOption(filterName, opt.filterKey, false))
      _selectOption(superfilter.name, superfilter.key)
    }
  }
  const _clearRestrictions = (filterName, filterKey, checking) => {
    // If restrictable, clear any options restricting this filter
    if (!restrictedFilters.includes(filterName)) return
    restrictingFilters.forEach((otherName) =>
      filterConfigs[otherName]?.restrictions?.forEach(({ name, key }) => {
        // If this filter restricts the initial one, and the required option either is the key that
        // got cleared or is different from the key that got set, then clear this filter's options
        if (name === filterName && (key === filterKey) !== checking) _clearOptions(otherName)
      }),
    )
  }
  const _clearRestricted = (filterName, filterKey) => {
    // Clear any options restricted by this filter
    filterConfigs[filterName]?.restrictions?.forEach(({ name, key }) => _clearOptions(name, { except: key }))
  }

  // Filter aggregate method and helpers
  const applyAggregates = (aggregates) => {
    Object.values(filterConfigs).forEach((filter) => {
      const aggOptions = aggregates[filter.source]?.buckets
      if (!aggOptions) return
      filter.dynamic ? _importOptions(filter, aggOptions) : _loadOptionCounts(filter, aggOptions)
      if (filter.superfilter) _setMasterCount(filter.name, filter.superfilter)
    })
  }
  const _importOptions = (filter, aggOptions) => {
    const uncheckedKeys = state.optionLists[filter.name]?.filter((opt) => !opt.checked).map((opt) => opt.filterKey)
    uncheckedKeys?.forEach((key) => removeOption(filter.name, key))
    aggOptions.forEach((aggOption) => {
      const newOpt = _aggregateToOption(aggOption, filter.translateOpts)
      const oldOpt = _getOption(filter.name, newOpt.filterKey)
      if (!oldOpt) return addOption(filter.name, newOpt)
      oldOpt.count = newOpt.count
      if (oldOpt.label) oldOpt.label = newOpt.label
      if (oldOpt.icon) oldOpt.icon = newOpt.icon
    })
    filter.loaded = true
  }
  const _loadOptionCounts = (filter, aggOptions) => {
    state.optionLists[filter.name]?.forEach((opt) => {
      const key = filter.convertSourceKeys ? opt.filterKey.replaceAll('_', '') : opt.filterKey
      opt.count = aggOptions.find((b) => b.key.toLowerCase() === key)?.doc_count?.toLocaleString() || '0'
    })
  }
  const _setMasterCount = (filterName, superfilter) => {
    // Get superfilter option and ensure it's a master type
    const masterOpt = _getOption(superfilter.name, superfilter.key)
    if (!masterOpt?.master) return

    // Set master count from tallied subfilter counts
    const addCount = (count, opt) => count + (opt.count ? parseInt(opt.count.replaceAll(/[^\d]/g, ''), 10) : 0)
    masterOpt.count = (state.optionLists[filterName] || []).reduce(addCount, 0).toLocaleString()
  }
  const _aggregateToOption = (aggOption, translate) => {
    const filterKey = aggOption.key.toString()
    const count = aggOption.doc_count.toLocaleString()
    const obj = { filterKey, count }
    if (!translate) obj.label = aggOption.name || upperFirst(filterKey)
    if (aggOption.logo) obj.icon = aggOption.logo
    return obj
  }

  const filterParams = computed(() => {
    const initialParams = {}
    if (state.query.length) {
      initialParams.q = state.query
    }
    if (state.sort !== DEFAULT_SORT) {
      initialParams.sort = state.sort
    }
    return Object.entries(state.selectedOptions).reduce((params, [name, value]) => {
      if (value.length) {
        params[name] = value
      }
      return params
    }, initialParams)
  })

  // Vue router automatically converts array value keys to param[]=
  // However, Rails always needs [] to identify array params
  const requestParams = computed(() => {
    return Object.entries(filterParams.value).reduce((params, [name, value]) => {
      if (!UNBRACKETED_FILTERS.includes(name)) {
        params[`${name}[]`] = value
      } else {
        params[`${name}`] = value
      }
      return params
    }, {})
  })

  return {
    state,
    filterLayout,
    getFilterConfig: (filterName) => (filterConfigs[filterName] ? readonly(filterConfigs[filterName]) : undefined),
    getOption: (filterName, key) => {
      const opt = _getOption(filterName, key)
      return opt ? readonly(opt) : undefined
    },
    getOptions: (filterName, authorOnly = false) => {
      const opts = state.optionLists[filterName] || []
      // FIXME: Wrap return in readonly() once on Vue 3
      return authorOnly ? opts.filter((opt) => !opt.authorOnly) : opts
    },
    anyOptionChecked: (filterName) => !!state.optionLists[filterName]?.find((opt) => opt.checked),
    clearFilters: () => {
      state.query = ''
      Object.keys(filterConfigs).forEach((name) => _clearOptions(name))
    },
    addOption,
    setOption,
    removeOption,
    applyAggregates,
    filterParams,
    requestParams,
    DEFAULT_SORT,
    RESULTS_PER_PAGE,
    QUERY_KEY,
  }
})
