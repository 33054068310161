/**
 “String” Method

 _.classify([string=''])

 Converts string to UpperCamelCase.  Named after rails #classify

 Arguments

 [string=''] (string): The string to convert.
 Returns

 (string): Returns the UpperCamelCase string.

 Example

 _.classify('Foo Bar');
 // => 'FooBar'

 _.classify('--foo-bar--');
 // => 'FooBar'

 _.classify('__FOO_BAR__');
 // => 'FooBar'
 */

import flow from 'lodash/flow'
import toString from 'lodash/toString'
import upperFirst from 'lodash/upperFirst'
import camelCase from 'lodash/camelCase'

export default (string) => flow([toString, camelCase, upperFirst])(string)
