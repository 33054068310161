import { location as $location } from 'utils'

function setupSearch() {
  const openBtn = document.getElementById('open-search')
  const closeBtn = document.getElementById('close-search')
  const searchNav = document.getElementById('search-nav')
  const rightNav = document.getElementById('right-nav')
  const getQueryBar = () => searchNav.querySelector('#query input')

  if (!(openBtn && closeBtn && searchNav && rightNav)) {
    return
  }

  const openSearch = () => {
    searchNav.classList.add('md:flex')
    rightNav.classList.remove('md:flex')
    getQueryBar()?.focus()
  }

  openBtn.onclick = openSearch
  closeBtn.onclick = () => {
    searchNav.classList.remove('md:flex')
    rightNav.classList.add('md:flex')
    getQueryBar().value = ''
  }

  if (onSearchPage()) {
    openSearch()
  }
}

function setupEmbedControls() {
  if (window && window.top !== window.self) {
    const hamburgerMenu = document.getElementById('hamburger-menu-container')
    const backBtn = document.getElementById('embed-back-btn')

    if (!(hamburgerMenu || backBtn)) {
      return
    }

    hamburgerMenu.classList.add('hidden')
    if (window.history.length > 1 && document.referrer.includes($location.current().host)) {
      backBtn.classList.remove('hidden')
      backBtn.onclick = () => window.history.back()
    }

    const homeIcons = document.getElementById('home-icons')
    homeIcons.href = document.getElementById('original-request-url').value
    homeIcons.target = '_blank'

    document.getElementById('footer').classList.add('hidden')
    document.getElementById('embed-footer').classList.remove('hidden')

    document.querySelector('#header > div').classList.remove('lg:h-16')
    document.querySelectorAll('#header .dropdown-pane').forEach((dropdown) => dropdown.classList.remove('lg:mt-4'))
  }
}

export default function () {
  setupEmbedControls()
  setupSearch()
}

function onSearchPage() {
  return window.location.pathname.split('/')[1] === 'search'
}
