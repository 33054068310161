/*
 * Search filter definitions
 *
 * Filters have the following properties:
 * - name: Filter name (required - also for i18n)
 * - options: Filtering options (see details below)
 * - config: Arguments to set optional attributes:
 *   - authorOnly: Only display for authors if true
 *   - wrap: Wrap options in a flex-row grid if true
 *   - toggle: Present options as toggle switches if true
 *   - dynamic: Populate options from result aggregates if true
 *   - scrollable: Wrap options in fixed-height scrollable box if true
 *   - translateOpts: Lookup translations for dynamic option labels if true
 *   - superfilter: Superfilter name and option key if subfilter
 *   - lookup: Option lookup filter name to add options if set
 *   - source: Which search aggregate to load data from if set
 *   - convertSourceKeys: Pascal-case keys in source lookup if true
 *   - restrictions: Comma-separated list of filter name/key pairs required by this filter
 *
 * Filter options have the following properties (optional unless specified):
 * - filterName: Filter name (automatically applied)
 * - filterKey: Filter key (required)
 * - checked: Whether option is checked (default: false)
 * - count: Result count locale string (default: '')
 * - label: Pre-translated label if present (for dynamic filters)
 * - icon: URL for display icon if present
 * - subfilterName: Name of option subfilter if present
 * - master: Acts as master switch for subfilter if true
 * - authorOnly: Only display for authors if true
 *
 * In the filter definitions below, static filters may specify initial options
 * to be generated as the filters are built. Initial options may be specified
 * with only a filterKey string, unless they require additional properties.
 *
 * We register filters in presentation order to preserve option sequencing.
 */
export default [
  {
    name: 'content_type',
    options: [
      { filterKey: 'wiki' },
      { filterKey: 'lesson_plan', subfilterName: 'lesson_type' },
      { filterKey: 'resource', subfilterName: 'resource_type', master: true, authorOnly: true },
    ],
    config: { source: 'type', convertSourceKeys: true },
  },
  {
    name: 'lesson_type',
    options: ['full', 'assessment', 'activity', 'video'],
    config: {
      superfilter: 'content_type:lesson_plan',
      source: 'lesson_type',
      restrictions: 'content_type:lesson_plan,resource_type:none',
    },
  },
  {
    name: 'resource_type',
    options: ['audio', 'document', 'geogebraitem', 'image', 'hyperlink', 'html', 'slide', 'video'],
    config: { superfilter: 'content_type:resource', source: 'type', convertSourceKeys: true },
  },
  { name: 'from', config: { source: 'customer_id', dynamic: true, scrollable: true } },
  { name: 'collections', config: { source: 'collection_names', dynamic: true, scrollable: true } },
  {
    name: 'subject',
    options: ['ela', 'math'],
    config: { source: 'subject_codes', restrictions: 'content_type:lesson_plan,resource_type:none' },
  },
  {
    name: 'grades',
    options: ['K', '1st', '2nd', '3rd', '4th', '5th', '6th', '7th', '8th', '9th', '10th', '11th', '12th'],
    config: {
      wrap: true,
      toggle: true,
      source: 'grade_levels_for_human',
      restrictions: 'content_type:lesson_plan,resource_type:none',
    },
  },
  {
    name: 'standards',
    config: { wrap: true, lookup: 'standards', restrictions: 'content_type:lesson_plan,resource_type:none' },
  },
  { name: 'premium', config: { source: 'premium', dynamic: true, translateOpts: true } },
  { name: 'state', config: { source: 'state', dynamic: true } },
]
