import { createPage, isOverflowing, moveBreakAfterAvoids, pageHeight, renderParentChain } from './page'
import { isGrid, isPrintedTag } from './dom'
import { splitText } from './text_splitter'

export default function breakPage(brokenElement, parentChain, page) {
  const newPage = createPage()
  const renderedParent = renderParentChain(parentChain, newPage, page)
  if (isOverflowing(newPage)) {
    let lastParent = renderedParent
    while (lastParent && !lastParent.classList.contains('page-content')) {
      lastParent.style['height'] = 'unset'
      lastParent = lastParent.parentElement
    }
  }

  let newChunk = brokenElement

  if (isPrintedTag(brokenElement) || isGrid(brokenElement)) {
    brokenElement.remove()
    renderedParent.appendChild(newChunk)
    if (isOverflowing(newPage)) {
      let lastParent = renderedParent
      while (!lastParent.classList.contains('page-content')) {
        lastParent.style.setProperty('height', 'unset', 'important')
        lastParent.style.setProperty('width', 'unset', 'important')
        lastParent.style.setProperty('margin', '0px', 'important')
        lastParent.style.setProperty('padding', '0px', 'important')
        lastParent = lastParent.parentElement
      }
      brokenElement.style['max-width'] = '100%'
      brokenElement.style['max-height'] = `${pageHeight(newPage) - 5}px`
      brokenElement.style['position'] = 'block'
      brokenElement.setAttribute('data-unbreakable', 'true')
    } else {
      moveBreakAfterAvoids(page, newPage)
    }
  } else {
    newChunk = brokenElement.cloneNode()
    // remove childNodes one word at a time
    // take removed children and render on next page
    Array.from(brokenElement.childNodes)
      .reverse()
      .every((node) => {
        return breakUpNode(node, newChunk, page)
      })
    renderedParent.appendChild(newChunk)
  }

  return { newChunk, newPage }
}

function breakUpNode(node, newChunk, page) {
  if (node.childNodes.length > 0) {
    const newNode = node.cloneNode()
    newChunk.prepend(newNode)
    Array.from(node.childNodes)
      .reverse()
      .every((node) => {
        return breakUpNode(node, newNode, page)
      })
  } else if (node.nodeName === '#text') {
    splitText(node, newChunk, page)
  } else {
    node.remove()
    newChunk.prepend(node)
  }
  return isOverflowing(page)
}
