import { ref, computed } from 'vue'

export function toggleNoScroll(val) {
  document.body.classList.toggle('no-scroll', val)
}

const modals = new Map()
// Map isn't reactive until Vue 3 so pair with a "tracker"
const tracker = ref(1)

export const isModalBusy = ref(false)

function touch() {
  tracker.value += 1
}

function extractId(component) {
  if (typeof component === 'string') return component
  if (component.name) return component.name
  throw new Error('Modal component must have a name attribute.')
}

export function openModal(component, payload = null) {
  const id = extractId(component)
  modals.set(id, { id, payload })
  touch()
  toggleNoScroll(true)
}

export function closeModal(component) {
  const id = extractId(component)
  modals.delete(id)
  touch()
  if (modals.size === 0) toggleNoScroll(false)
}

export function closeAllModals() {
  modals.clear()
  touch()
  toggleNoScroll(false)
}

export function canModalShow(component) {
  const id = extractId(component)
  return tracker.value && modals.has(id)
}

export const activeModal = computed(() => {
  if (tracker.value && modals.size === 0) return {}
  return [...modals][modals.size - 1][1]
})
