import { reactive } from 'vue'
import { defineStore } from 'vue_features/shared/composables/store_helpers.js'

export const useFeatureFlagsStore = defineStore('featureFlags', () => {
  const state = reactive({
    flags: {},
  })

  const isEnabled = (flag) => state.flags[flag] || false
  const setFlag = (flag, enabledState) => (state.flags[flag] = enabledState)

  return {
    state,
    isEnabled,
    setFlag,
  }
})
