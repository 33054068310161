import { metaTags, toBoolean } from 'utils'

export default function () {
  document.querySelectorAll('.vue-dropdown').forEach((dropdownContainer) => {
    const dropdown = dropdownContainer.querySelector('.dropdown-pane')
    reflowOnResize(dropdownContainer, dropdown)
    const toggleBtn = dropdownContainer.querySelector('[data-close-on-click]')
    const shouldCloseOnClick = toBoolean(toggleBtn.getAttribute('data-close-on-click'))
    toggleBtn.ariaExpanded = dropdown.classList.contains('is-open')

    toggleBtn.addEventListener('click', () => {
      if (dropdown.classList.contains('is-open')) {
        dropdown.classList.remove('is-open')
        toggleBtn.ariaExpanded = 'false'
      } else {
        dropdown.classList.add('is-open')
        toggleBtn.ariaExpanded = 'true'
        reflow(dropdownContainer, dropdown)
        if (shouldCloseOnClick) {
          closeOnClick(toggleBtn, dropdown)
        }
      }
    })
  })

  document.querySelectorAll('[data-logout-path]').forEach((button) => {
    const path = button.getAttribute('data-logout-path')
    if (!path) return
    button.addEventListener('click', () => {
      const form = document.createElement('form')
      form.method = 'POST'
      form.action = path
      addInput(form, '_method', 'delete')
      addInput(form, 'authenticity_token', metaTags.csrfToken())
      document.body.appendChild(form)
      form.submit()
    })
  })
}

function addInput(form, key, value) {
  const input = document.createElement('input')
  input.type = 'hidden'
  input.name = key
  input.value = value
  form.appendChild(input)
}

function closeOnClick(toggleElement, dropdown) {
  const closeOnClick = (e) => {
    if (!toggleElement.contains(e.target) && !(e.target.tagName === 'INPUT' && dropdown.contains(e.target))) {
      dropdown.classList.remove('is-open')
      document.body.removeEventListener('click', closeOnClick)
      dropdown.removeEventListener('click', closeOnClick)
    }
  }
  document.body.addEventListener('click', closeOnClick)
  dropdown.addEventListener('click', closeOnClick)
}

function reflow(parentContainer, dropdown) {
  const parentLeft = parentContainer.getBoundingClientRect().left
  if (window.innerWidth < parentLeft + dropdown.offsetWidth) {
    dropdown.classList.add('flow-left')
  } else {
    dropdown.classList.remove('flow-left')
  }
}

function reflowOnResize(parentContainer, dropdown) {
  const reflowFnc = () => reflow(parentContainer, dropdown)
  window.addEventListener('resize', reflowFnc)
}
