<script setup>
const props = defineProps({
  color: {
    type: String,
    default: Object.keys(COLORS)[0],
    validator: (value) => Object.keys(COLORS).includes(value),
  },
})
const colorClasses = COLORS[props.color]
</script>

<template>
  <span
    class="w-fit inline-flex h-7 items-center gap-1 rounded-2xl px-3 text-xs font-bold capitalize leading-6 print:bg-transparent print:text-black"
    :class="colorClasses"
  >
    <slot />
  </span>
</template>

<script>
const COLORS = {
  base: 'bg-neutral-400 text-primary-800',
  accent: 'bg-accent text-white',
  green: 'bg-green-100 text-green-600',
  pink: 'bg-pink-50 text-pink-700',
  yellow: 'bg-yellow-50 text-yellow-600',
  gray: 'bg-neutral-100 text-neutral-700',
  'dark-gray': 'bg-neutral-800 text-white',
}
</script>
