import { findSharedParent, getBreakStyle, isOverflown, loadImages, selectByDataRef, deepClone } from './dom'

export const INNER_PAGE = '<div class="page-content"></div><div class="page-footer"></div>'

export function createPage() {
  const page = document.createElement('div')
  page.classList.add('page')
  page.setAttribute('data-ref', getPageNumber())
  page.innerHTML = INNER_PAGE
  const title = document.createElement('p')
  title.textContent = document.querySelector('.title').textContent
  page.querySelector('.page-footer').appendChild(title)
  document.getElementById('pages-container').appendChild(page)
  return page
}

export function renderParentChain(chain, page, oldPage) {
  let renderedParent = page.firstElementChild
  let parentIndex = -1
  const reversedParents = [...chain].reverse()
  reversedParents.every((parent, index) => {
    const newParent = selectByDataRef(parent, page)
    if (newParent) {
      renderedParent = newParent
      parentIndex = Math.abs(index - (chain.length - 1))
      return false
    }
    return true
  })

  for (let i = parentIndex + 1; i < chain.length; i++) {
    if (chain[i].nodeName === 'TD' && oldPage) {
      ensureTableRows(renderedParent, selectByDataRef(chain[i], oldPage))
    }
    renderedParent = renderedParent.appendChild(chain[i].cloneNode())
    adjustProportions(renderedParent)
  }
  return renderedParent
}

function adjustProportions(renderedParent) {
  const height = Number.parseInt(renderedParent.style.height)
  if (height > 950) {
    renderedParent.style.height = '90%'
  }
  const width = Number.parseInt(renderedParent.style.width)
  if (width > 700) {
    renderedParent.style.width = '90%'
  }
}

function ensureTableRows(parent, tdNode) {
  let sibling = tdNode.previousElementSibling
  while (sibling) {
    const newTD = parent.appendChild(sibling.cloneNode())
    newTD.style.width = sibling.clientWidth
    sibling = sibling.previousElementSibling
  }
}

export async function renderElement(element, parentChain, page) {
  const renderedParent = renderParentChain(parentChain, page)
  const renderedElement = renderedParent.appendChild(deepClone(element))
  await loadImages(renderedElement)
  return renderedElement
}

export function moveBreakAfterAvoids(page, newPage) {
  const element = lastBreakAfterElement(page)
  if (element) {
    const { newParent, oldParent, movedSubtree } = findSharedParent(element, page, newPage)
    if (newParent) {
      movedSubtree.remove()
      newParent.prepend(movedSubtree)
      if (isOverflowing(newPage)) {
        movedSubtree.remove()
        oldParent.appendChild(movedSubtree)
      }
    }
  }
}

function lastBreakAfterElement(page) {
  let element = page.querySelector('.page-content').lastElementChild
  if (!element) {
    return
  }

  let lastLooked = element

  while (lastLooked && getBreakStyle(lastLooked)['after'] !== 'avoid') {
    lastLooked = lastLooked.lastElementChild
    if (lastLooked) {
      element = lastLooked
    }
  }

  return getBreakStyle(element)['after'] === 'avoid' ? element : null
}

export function isOverflowing(page) {
  return isOverflown(page.querySelector('.page-content'))
}

export function pageHeight(page) {
  const contentContainer = page.querySelector('.page-content')
  const computedStyle = getComputedStyle(contentContainer)
  return contentContainer.clientHeight - parseFloat(computedStyle.paddingTop)
}

let pageNumber = 0
function getPageNumber() {
  return `page-${pageNumber++}`
}
