import { CONFIG } from 'rails_constants'

export const {
  asset,
  coverImage,
  customer,
  pagination,
  image,
  imageWithSvg,
  video,
  videoCaption,
  document,
  wiki,
  audio,
  file,
  lzCode,
  fullStory,
  publicCustomerId,
  returnToCookieName,
  webSocketConfig,
} = CONFIG
