import Cookies from 'js-cookie'
import platform from 'platform'

const CookiesWithDefaults = Cookies.withAttributes({
  sameSite: 'none',
  secure: true,
  partitioned: isSupportedBrowser(),
})

// Check if the user's browser supports partitioned cookies based on the compatibility table on this page:
// https://developer.mozilla.org/en-US/docs/Web/Privacy/Partitioned_cookies
function isSupportedBrowser() {
  const name = platform.name
  const version = parseInt(platform.version.split('.')[0], 10)

  return (name === 'Chrome' || name === 'Edge') && version >= 114
}

export default Object.create({
  get: CookiesWithDefaults.get,
  set: function (key, value, attributes) {
    // Delete old unpartitioned cookies. If they aren't deleted, then there will be two cookies with the same name.
    // All users will have an unpartitioned cookies so this is a safe way to transition them to partitioned ones
    // without forcing them to delete all their cookies.
    // TODO: Eventually remove this because all cookies will be partitioned.
    if (CookiesWithDefaults.attributes.partitioned) {
      CookiesWithDefaults.remove(key, { partitioned: false })
    }

    return CookiesWithDefaults.set(key, value, attributes)
  },
  remove: CookiesWithDefaults.remove,
  clear: function () {
    Object.keys(CookiesWithDefaults.get()).forEach((key) => {
      CookiesWithDefaults.remove(key, {})
    })
  },
})
