export const currentSeason = () => {
  const d = new Date()
  const fullYear = d.getFullYear()
  const isLeapYear = fullYear % 4 === 0
  const seasonArray = [
    { name: 'spring', date: new Date(fullYear, 2, isLeapYear ? 19 : 20).getTime() },
    { name: 'summer', date: new Date(fullYear, 5, isLeapYear ? 20 : 21).getTime() },
    { name: 'fall', date: new Date(fullYear, 8, isLeapYear ? 22 : 23).getTime() },
    { name: 'winter', date: new Date(fullYear, 11, isLeapYear ? 20 : 21).getTime() },
  ]
  const season = seasonArray.filter(({ date }) => date <= d).slice(-1)[0] || { name: 'winter' }
  return season.name
}

export const themeArray = {
  k5: {
    default: {
      title: 'Default',
      background: '',
      'zero-klasses': require('images/themes/k5/default/zero-k5-klasses-default.png'),
      'zero-students': require('images/themes/k5/default/zero-k5-students-default.png'),
      'zero-assignments': require('images/themes/k5/default/zero-k5-assignments-default.png'),
    },
    seasonal: {
      title: 'Seasonal',
      background: require(`images/themes/k5/seasonal/background-k5-seasonal-${currentSeason()}.png`),
      'zero-klasses': require(`images/themes/k5/seasonal/zero-k5-assignments-seasonal-${currentSeason()}.png`),
      'zero-students': require(`images/themes/k5/seasonal/zero-k5-assignments-seasonal-${currentSeason()}.png`),
      'zero-assignments': require(`images/themes/k5/seasonal/zero-k5-assignments-seasonal-${currentSeason()}.png`),
    },
    space: {
      title: 'Space',
      background: require(`images/themes/k5/space/background-k5-space.png`),
      'zero-klasses': require('images/themes/k5/space/zero-k5-assignments-space.png'),
      'zero-students': require('images/themes/k5/space/zero-k5-assignments-space.png'),
      'zero-assignments': require('images/themes/k5/space/zero-k5-assignments-space.png'),
    },
    smoothie: {
      title: 'Smoothie Shop',
      background: require(`images/themes/k5/smoothie/background-k5-smoothie.png`),
      'zero-klasses': require('images/themes/k5/smoothie/zero-k5-assignments-smoothie.png'),
      'zero-students': require('images/themes/k5/smoothie/zero-k5-assignments-smoothie.png'),
      'zero-assignments': require('images/themes/k5/smoothie/zero-k5-assignments-smoothie.png'),
    },
    dino: {
      title: 'Dino Land',
      background: require(`images/themes/k5/dino/background-k5-dino.png`),
      'zero-klasses': require('images/themes/k5/dino/zero-k5-assignments-dino.png'),
      'zero-students': require('images/themes/k5/dino/zero-k5-assignments-dino.png'),
      'zero-assignments': require('images/themes/k5/dino/zero-k5-assignments-dino.png'),
    },
  },
  612: {
    default: {
      title: 'Default',
      background: '',
      'zero-klasses': require('images/themes/612/default/zero-612-klasses-default.png'),
      'zero-students': require('images/themes/612/default/zero-612-students-default.png'),
      'zero-assignments': require('images/themes/612/default/zero-612-assignments-default.png'),
    },
    seasonal: {
      title: 'Seasonal',
      background: require(`images/themes/612/seasonal/background-612-seasonal-${currentSeason()}.png`),
      'zero-klasses': require(`images/themes/612/seasonal/zero-612-assignments-seasonal-${currentSeason()}.png`),
      'zero-students': require(`images/themes/612/seasonal/zero-612-assignments-seasonal-${currentSeason()}.png`),
      'zero-assignments': require(`images/themes/612/seasonal/zero-612-assignments-seasonal-${currentSeason()}.png`),
    },
    greece: {
      title: 'Greece',
      background: require(`images/themes/612/greece/background-612-greece.png`),
      'zero-klasses': require('images/themes/612/greece/zero-612-assignments-greece.png'),
      'zero-students': require('images/themes/612/greece/zero-612-assignments-greece.png'),
      'zero-assignments': require('images/themes/612/greece/zero-612-assignments-greece.png'),
    },
    france: {
      title: 'France',
      background: require(`images/themes/612/france/background-612-france.png`),
      'zero-klasses': require('images/themes/612/france/zero-612-assignments-france.png'),
      'zero-students': require('images/themes/612/france/zero-612-assignments-france.png'),
      'zero-assignments': require('images/themes/612/france/zero-612-assignments-france.png'),
    },
    italy: {
      title: 'Italy',
      background: require(`images/themes/612/italy/background-612-italy.png`),
      'zero-klasses': require('images/themes/612/italy/zero-612-assignments-italy.png'),
      'zero-students': require('images/themes/612/italy/zero-612-assignments-italy.png'),
      'zero-assignments': require('images/themes/612/italy/zero-612-assignments-italy.png'),
    },
    japan: {
      title: 'Japan',
      background: require(`images/themes/612/japan/background-612-japan.png`),
      'zero-klasses': require('images/themes/612/japan/zero-612-assignments-japan.png'),
      'zero-students': require('images/themes/612/japan/zero-612-assignments-japan.png'),
      'zero-assignments': require('images/themes/612/japan/zero-612-assignments-japan.png'),
    },
    brazil: {
      title: 'Brazil',
      background: require(`images/themes/612/brazil/background-612-brazil.png`),
      'zero-klasses': require('images/themes/612/brazil/zero-612-assignments-brazil.png'),
      'zero-students': require('images/themes/612/brazil/zero-612-assignments-brazil.png'),
      'zero-assignments': require('images/themes/612/brazil/zero-612-assignments-brazil.png'),
    },
    morocco: {
      title: 'Morocco',
      background: require(`images/themes/612/morocco/background-612-morocco.png`),
      'zero-klasses': require('images/themes/612/morocco/zero-612-assignments-morocco.png'),
      'zero-students': require('images/themes/612/morocco/zero-612-assignments-morocco.png'),
      'zero-assignments': require('images/themes/612/morocco/zero-612-assignments-morocco.png'),
    },
    india: {
      title: 'India',
      background: require(`images/themes/612/india/background-612-india.png`),
      'zero-klasses': require('images/themes/612/india/zero-612-assignments-india.png'),
      'zero-students': require('images/themes/612/india/zero-612-assignments-india.png'),
      'zero-assignments': require('images/themes/612/india/zero-612-assignments-india.png'),
    },
  },
}

export const avatarArray = {
  k5: {
    polly: {
      title: 'Polly',
      modal: require('images/avatars/k5/polly/avatar-polly-modal.png'),
      base: require('images/avatars/k5/polly/avatar-polly-base-full.png'),
      seasonal: require(`images/avatars/k5/polly/avatar-polly-${currentSeason()}-full.png`),
      smoothie: require('images/avatars/k5/polly/avatar-polly-smoothie-full.png'),
      space: require('images/avatars/k5/polly/avatar-polly-space-full.png'),
      dino: require('images/avatars/k5/polly/avatar-polly-dino-full.png'),
    },
    bonnie: {
      title: 'Bonnie',
      modal: require('images/avatars/k5/bonnie/avatar-bonnie-modal.png'),
      base: require('images/avatars/k5/bonnie/avatar-bonnie-base-full.png'),
      seasonal: require(`images/avatars/k5/bonnie/avatar-bonnie-${currentSeason()}-full.png`),
      smoothie: require('images/avatars/k5/bonnie/avatar-bonnie-smoothie-full.png'),
      space: require('images/avatars/k5/bonnie/avatar-bonnie-space-full.png'),
      dino: require('images/avatars/k5/bonnie/avatar-bonnie-dino-full.png'),
    },
    abbey: {
      title: 'Abbey',
      modal: require('images/avatars/k5/abbey/avatar-abbey-modal.png'),
      base: require('images/avatars/k5/abbey/avatar-abbey-base-full.png'),
      seasonal: require(`images/avatars/k5/abbey/avatar-abbey-${currentSeason()}-full.png`),
      smoothie: require('images/avatars/k5/abbey/avatar-abbey-smoothie-full.png'),
      space: require('images/avatars/k5/abbey/avatar-abbey-space-full.png'),
      dino: require('images/avatars/k5/abbey/avatar-abbey-dino-full.png'),
    },
    fleur: {
      title: 'Fleur',
      modal: require('images/avatars/k5/fleur/avatar-fleur-modal.png'),
      base: require('images/avatars/k5/fleur/avatar-fleur-base-full.png'),
      seasonal: require(`images/avatars/k5/fleur/avatar-fleur-${currentSeason()}-full.png`),
      smoothie: require('images/avatars/k5/fleur/avatar-fleur-smoothie-full.png'),
      space: require('images/avatars/k5/fleur/avatar-fleur-space-full.png'),
      dino: require('images/avatars/k5/fleur/avatar-fleur-dino-full.png'),
    },
    percy: {
      title: 'Percy',
      modal: require('images/avatars/k5/percy/avatar-percy-modal.png'),
      base: require('images/avatars/k5/percy/avatar-percy-base-full.png'),
      seasonal: require(`images/avatars/k5/percy/avatar-percy-${currentSeason()}-full.png`),
      smoothie: require('images/avatars/k5/percy/avatar-percy-smoothie-full.png'),
      space: require('images/avatars/k5/percy/avatar-percy-space-full.png'),
      dino: require('images/avatars/k5/percy/avatar-percy-dino-full.png'),
    },
    sammy: {
      title: 'Sammy',
      modal: require('images/avatars/k5/sammy/avatar-sammy-modal.png'),
      base: require('images/avatars/k5/sammy/avatar-sammy-base-full.png'),
      seasonal: require(`images/avatars/k5/sammy/avatar-sammy-${currentSeason()}-full.png`),
      smoothie: require('images/avatars/k5/sammy/avatar-sammy-smoothie-full.png'),
      space: require('images/avatars/k5/sammy/avatar-sammy-space-full.png'),
      dino: require('images/avatars/k5/sammy/avatar-sammy-dino-full.png'),
    },
  },
  612: {
    leila: {
      title: 'Leila',
      modal: require('images/avatars/612/leila/avatar-leila-modal.png'),
      base: require('images/avatars/612/leila/avatar-leila-base-full.png'),
      seasonal: require(`images/avatars/612/leila/avatar-leila-${currentSeason()}-full.png`),
      greece: require('images/avatars/612/leila/avatar-leila-travel-full.png'),
      france: require('images/avatars/612/leila/avatar-leila-travel-full.png'),
      italy: require('images/avatars/612/leila/avatar-leila-travel-full.png'),
      japan: require('images/avatars/612/leila/avatar-leila-travel-full.png'),
      brazil: require('images/avatars/612/leila/avatar-leila-travel-full.png'),
      morocco: require('images/avatars/612/leila/avatar-leila-travel-full.png'),
      india: require('images/avatars/612/leila/avatar-leila-travel-full.png'),
    },
    omar: {
      title: 'Omar',
      modal: require('images/avatars/612/omar/avatar-omar-modal.png'),
      base: require('images/avatars/612/omar/avatar-omar-base-full.png'),
      seasonal: require(`images/avatars/612/omar/avatar-omar-${currentSeason()}-full.png`),
      greece: require('images/avatars/612/omar/avatar-omar-travel-full.png'),
      france: require('images/avatars/612/omar/avatar-omar-travel-full.png'),
      italy: require('images/avatars/612/omar/avatar-omar-travel-full.png'),
      japan: require('images/avatars/612/omar/avatar-omar-travel-full.png'),
      brazil: require('images/avatars/612/omar/avatar-omar-travel-full.png'),
      morocco: require('images/avatars/612/omar/avatar-omar-travel-full.png'),
      india: require('images/avatars/612/omar/avatar-omar-travel-full.png'),
    },
    daniel: {
      title: 'Daniel',
      modal: require('images/avatars/612/daniel/avatar-daniel-modal.png'),
      base: require('images/avatars/612/daniel/avatar-daniel-base-full.png'),
      seasonal: require(`images/avatars/612/daniel/avatar-daniel-${currentSeason()}-full.png`),
      greece: require('images/avatars/612/daniel/avatar-daniel-travel-full.png'),
      france: require('images/avatars/612/daniel/avatar-daniel-travel-full.png'),
      italy: require('images/avatars/612/daniel/avatar-daniel-travel-full.png'),
      japan: require('images/avatars/612/daniel/avatar-daniel-travel-full.png'),
      brazil: require('images/avatars/612/daniel/avatar-daniel-travel-full.png'),
      morocco: require('images/avatars/612/daniel/avatar-daniel-travel-full.png'),
      india: require('images/avatars/612/daniel/avatar-daniel-travel-full.png'),
    },
    paige: {
      title: 'Paige',
      modal: require('images/avatars/612/paige/avatar-paige-modal.png'),
      base: require('images/avatars/612/paige/avatar-paige-base-full.png'),
      seasonal: require(`images/avatars/612/paige/avatar-paige-${currentSeason()}-full.png`),
      greece: require('images/avatars/612/paige/avatar-paige-travel-full.png'),
      france: require('images/avatars/612/paige/avatar-paige-travel-full.png'),
      italy: require('images/avatars/612/paige/avatar-paige-travel-full.png'),
      japan: require('images/avatars/612/paige/avatar-paige-travel-full.png'),
      brazil: require('images/avatars/612/paige/avatar-paige-travel-full.png'),
      morocco: require('images/avatars/612/paige/avatar-paige-travel-full.png'),
      india: require('images/avatars/612/paige/avatar-paige-travel-full.png'),
    },
    helen: {
      title: 'Helen',
      modal: require('images/avatars/612/helen/avatar-helen-modal.png'),
      base: require('images/avatars/612/helen/avatar-helen-base-full.png'),
      seasonal: require(`images/avatars/612/helen/avatar-helen-${currentSeason()}-full.png`),
      greece: require('images/avatars/612/helen/avatar-helen-travel-full.png'),
      france: require('images/avatars/612/helen/avatar-helen-travel-full.png'),
      italy: require('images/avatars/612/helen/avatar-helen-travel-full.png'),
      japan: require('images/avatars/612/helen/avatar-helen-travel-full.png'),
      brazil: require('images/avatars/612/helen/avatar-helen-travel-full.png'),
      morocco: require('images/avatars/612/helen/avatar-helen-travel-full.png'),
      india: require('images/avatars/612/helen/avatar-helen-travel-full.png'),
    },
    oliver: {
      title: 'Oliver',
      modal: require('images/avatars/612/oliver/avatar-oliver-modal.png'),
      base: require('images/avatars/612/oliver/avatar-oliver-base-full.png'),
      seasonal: require(`images/avatars/612/oliver/avatar-oliver-${currentSeason()}-full.png`),
      greece: require('images/avatars/612/oliver/avatar-oliver-travel-full.png'),
      france: require('images/avatars/612/oliver/avatar-oliver-travel-full.png'),
      italy: require('images/avatars/612/oliver/avatar-oliver-travel-full.png'),
      japan: require('images/avatars/612/oliver/avatar-oliver-travel-full.png'),
      brazil: require('images/avatars/612/oliver/avatar-oliver-travel-full.png'),
      morocco: require('images/avatars/612/oliver/avatar-oliver-travel-full.png'),
      india: require('images/avatars/612/oliver/avatar-oliver-travel-full.png'),
    },
    lucy: {
      title: 'Lucy',
      modal: require('images/avatars/612/lucy/avatar-lucy-modal.png'),
      base: require('images/avatars/612/lucy/avatar-lucy-base-full.png'),
      seasonal: require(`images/avatars/612/lucy/avatar-lucy-${currentSeason()}-full.png`),
      greece: require('images/avatars/612/lucy/avatar-lucy-travel-full.png'),
      france: require('images/avatars/612/lucy/avatar-lucy-travel-full.png'),
      italy: require('images/avatars/612/lucy/avatar-lucy-travel-full.png'),
      japan: require('images/avatars/612/lucy/avatar-lucy-travel-full.png'),
      brazil: require('images/avatars/612/lucy/avatar-lucy-travel-full.png'),
      morocco: require('images/avatars/612/lucy/avatar-lucy-travel-full.png'),
      india: require('images/avatars/612/lucy/avatar-lucy-travel-full.png'),
    },
    ashley: {
      title: 'Ashley',
      modal: require('images/avatars/612/ashley/avatar-ashley-modal.png'),
      base: require('images/avatars/612/ashley/avatar-ashley-base-full.png'),
      seasonal: require(`images/avatars/612/ashley/avatar-ashley-${currentSeason()}-full.png`),
      greece: require('images/avatars/612/ashley/avatar-ashley-travel-full.png'),
      france: require('images/avatars/612/ashley/avatar-ashley-travel-full.png'),
      italy: require('images/avatars/612/ashley/avatar-ashley-travel-full.png'),
      japan: require('images/avatars/612/ashley/avatar-ashley-travel-full.png'),
      brazil: require('images/avatars/612/ashley/avatar-ashley-travel-full.png'),
      morocco: require('images/avatars/612/ashley/avatar-ashley-travel-full.png'),
      india: require('images/avatars/612/ashley/avatar-ashley-travel-full.png'),
    },
    diego: {
      title: 'Diego',
      modal: require('images/avatars/612/diego/avatar-diego-modal.png'),
      base: require('images/avatars/612/diego/avatar-diego-base-full.png'),
      seasonal: require(`images/avatars/612/diego/avatar-diego-${currentSeason()}-full.png`),
      greece: require('images/avatars/612/diego/avatar-diego-travel-full.png'),
      france: require('images/avatars/612/diego/avatar-diego-travel-full.png'),
      italy: require('images/avatars/612/diego/avatar-diego-travel-full.png'),
      japan: require('images/avatars/612/diego/avatar-diego-travel-full.png'),
      brazil: require('images/avatars/612/diego/avatar-diego-travel-full.png'),
      morocco: require('images/avatars/612/diego/avatar-diego-travel-full.png'),
      india: require('images/avatars/612/diego/avatar-diego-travel-full.png'),
    },
  },
}
